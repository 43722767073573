import { useParams, useLocation } from 'react-router-dom';
import { BaseQueryDTO } from '../../../api/DTO';
import { UpsertLesson } from './upsert-lesson.component';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const CreateLessonController = () => {
    const query = useQuery();
    return (
      <UpsertLesson courseId={query.get('courseId')} />
    );
}

const UpdateLessonController = () => {
    const { id } = useParams<BaseQueryDTO>();
    return (
        <UpsertLesson id={id} />
    );
}

export { CreateLessonController, UpdateLessonController };
