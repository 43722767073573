import { useParams } from 'react-router-dom';
import { BaseQueryDTO } from '../../../api/DTO';
import { UpsertCollectiveAd } from './upsert-collective-ads.component';


const UpdateCollectiveAdsController = () => {
  const { id } = useParams<BaseQueryDTO>();
  return <UpsertCollectiveAd id={ id }/>
}

const CreateCollectiveAdsController = () => <UpsertCollectiveAd />;

export { UpdateCollectiveAdsController, CreateCollectiveAdsController };
