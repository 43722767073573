import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseQueryDTO } from '../../../../api/DTO';
import { UpsertSubpage } from './upsert-subpage.component';
import { SubpageTranslationsList } from '../subpage-translations/subpage-translations-list/subpage-translations-list';
import { readPageById } from '../../../../api/pages.api';
import { Page } from '../../../../types';


const CreateSubpageController = () => <UpsertSubpage />;

const UpdateSubpageController = () => {
	const [page, setPage] = useState<Page | undefined>(undefined);
	const { id } = useParams<BaseQueryDTO>();

	useEffect(() => {
		if (id) readPageById(id).then(setPage);
	}, [id]);

	return (
		<>
			<UpsertSubpage subpageId={id} />
			{id && page?.pageTranslations &&
				<SubpageTranslationsList subpageId={id} translations={page.pageTranslations} />}
		</>
	);
}

export { CreateSubpageController, UpdateSubpageController };
