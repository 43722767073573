import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Image } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import { FileResponseDTO } from '../../../api/DTO';
import { readAllFiles } from '../../../api/files.api';
import { DataList } from '../../../components';
import { placeholderImage } from '../../../utils/constants/placeholder-image';
import './files-list.styles.css';

export const FilesList = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [files, setFiles] = useState<Array<FileResponseDTO>>([]);
  const history = useHistory();
  
  useEffect(() => {
    readAllFiles().then(setFiles);
  }, [setFiles]);

  const handleCreate = (): void => {
    history.push('/files/create');
  }
  const handleEdit = (id: number): void => undefined;
  const handleDelete = (id: number): void => undefined;

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDelete(selectedItemId);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dataModel = [
    { title: 'ID', dataIndex: 'id' },
    {
      title: 'Preview',
      dataIndex: 'name',
      render: (name: string) => (
        <Image
          alt="File Preview"
          src={`${process.env.REACT_APP_BOT_URL}/files/download?fileName=${name}`}
          className="preview"
          placeholder={<FileImageOutlined />}
          fallback={placeholderImage}
        />),
      align: "center",
    },
    {
      title: 'Ссылка',
      dataIndex: 'name',
      render: (name: string) =>
        (<a href={`${process.env.REACT_APP_BOT_URL}/files/download?fileName=${name}`}>{name}</a>),
    },
    { title: 'MIME', dataIndex: 'contentType' },
    { title: 'Размер', dataIndex: 'size' }
  ];

  return (
    <>
      <DataList
        title="Файлы"
        dataModel={dataModel}
        data={files}
        onCreateButtonClick={() => handleCreate()}
        onDeleteButtonClick={(id) => {
          showModal();
          setSelectedItemId(id);
        }}
        onEditButtonClick={(id) => handleEdit(id)}
      />
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить этот файл?</p>
      </Modal>
    </>
  );
}
