import { APIFetch } from '../common/api-request';
import {
  Page, PagesListAllResponseDTO, CreatePageDTO, PageTranslation,
  CreatePageTranslationDTO, UpdatePageTranslationDTO,
} from '../types';


async function readAllPages (parentId?: string): Promise<Array<PagesListAllResponseDTO>> {
  const response = await APIFetch({
    method: 'GET',
    path: 'pages/all',
    queryParams: parentId ? { parentId: parentId } : undefined,
    isAuthorized: true,
  });

  return response.json();
}

async function readPageById (id: string): Promise<Page> {
  const response = await APIFetch({
    method: 'GET',
    path: 'pages',
    queryParams: { id },
    isAuthorized: true,
  });
  return response.json();
}

async function createPage (body: CreatePageDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'pages',
    payload: body,
    isAuthorized: true,
  });
}

async function updatePage (id: string, body: CreatePageDTO): Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'pages',
    queryParams: { id },
    payload: body,
    isAuthorized: true,
  });
}

async function deletePage (id: number|string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'pages',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

async function readPageTranslationsById (id: string): Promise<PageTranslation> {
  const response = await APIFetch({
    method: 'GET',
    path: 'page-translations',
    queryParams: { id },
    isAuthorized: true,
  });
  return response.json();
}

async function createPageTranslations (body: CreatePageTranslationDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'page-translations',
    payload: body,
    isAuthorized: true,
  });
}

async function updatePageTranslations (id: string, body: UpdatePageTranslationDTO): Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'page-translations',
    queryParams: { id },
    payload: body,
    isAuthorized: true,
  });
}

async function deletePageTranslations (id: number|string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'page-translations',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

export {
  readAllPages, readPageById, createPage, updatePage, deletePage,
  readPageTranslationsById, createPageTranslations, 
  updatePageTranslations, deletePageTranslations,
};
