import React, { useState } from 'react';
import { Button, Collapse, Modal } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { createContent, updateContent, deleteContent } from '../../../../api/content.api';
import { PageEditorQuill } from '../../../../components';
import { Content, PageContentResponseDTO } from '../../../../types';

type PageContentProps = {
	pageTranslationId: number | string;
	pageViews: Content[];
}


export const PageTranslationContent = ({ pageTranslationId, pageViews }: PageContentProps) => {
	const { Panel } = Collapse;
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [selectedItemId, setSelectedItemId] = useState<PageContentResponseDTO | undefined>();
	const [content, setContent] = useState<Array<PageContentResponseDTO>>(pageViews ?? []);
	const [editedPageID, setEditedPageID] = useState<number | null>(null);
	const [pageContent, setPageContent] = useState<string>('');


	const handleEditContentClick = (event: React.MouseEvent, contentItem: PageContentResponseDTO): void => {
		setEditedPageID(contentItem.id);
		event.stopPropagation();
	}

	const handleDeleteContentClick = (contentItem: PageContentResponseDTO): void => {
		deleteContent(contentItem.id)
			.then(() => {
				setContent(content?.filter(element => element.id !== contentItem.id));
			});
	}

	const handleCreateContentClick = (): void => {
		createContent({ pageTranslationId: Number(pageTranslationId), body: '' })
			.then(response => {
				setContent([...content, response]);
			});
	}

	const handlePageReady = () => {
		updateContent(editedPageID as number, { body: pageContent })
			.then(() => {
				setEditedPageID(null);
				setContent(content?.map(page => {
					if (page.id === editedPageID) {
						page.body = pageContent;
					}
					return page;
				}))
			});
	}

	
	const handleOk = () => {
		setIsModalVisible(false);
		if (selectedItemId) {
			handleDeleteContentClick(selectedItemId);
		}
	};

	const showModal = () => {
		setIsModalVisible(true);
	};
		
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
			<>
				<h2 style={{ marginTop: '20px' }}>Контент</h2>
				<Collapse accordion={true}>
				{
					pageViews?.map((item) =>
						<Panel
							header={`Контент ${item.id}`}
							key={item.id}
							extra={[
								<Button
									icon={<EditOutlined />}
									type="text"
									key={'button-edit'}
									onClick={event => handleEditContentClick(event, item)}
								/>,
								<Button
									icon={<DeleteOutlined />}
									type="text"
									key={'button-delete'}
									onClick={() => {
										showModal();
										setSelectedItemId(item);
									}}
								/>
								]}
							>
								{ item.id !== editedPageID ?
									<div dangerouslySetInnerHTML={{ __html: item.body }} /> :
									<>
										<PageEditorQuill
											body={item.body ?? pageContent}
											onChange={(body) => setPageContent(body)}
											label="Описание урока"
										/>
										<Button style={{ marginTop: '10px'}} onClick={handlePageReady} htmlType="button">Сохранить</Button>
									</>
								}
						</Panel>
					)
				}
			</Collapse>
			<Button
				icon={<PlusCircleOutlined/>}
				onClick={handleCreateContentClick}
			>
				Создать
			</Button>
			<Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<p>Вы действительно хотите удалить этот контент?</p>
			</Modal>
		</>
	);
}
