import {useHistory} from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { login } from '../../api/auth.api';


export const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const handleFinish = (): void => {
    const formData = form.getFieldsValue();
    login(formData)
      .then((res) => {
        localStorage.setItem('adminToken', res.jwt);
        history.push('/courses');
      })
      .catch(() => {
        alert('Неверный логин или пароль');
      });
  }

  return (
    <div>
      <h1>Авторизация</h1>
      <Form form={form} onFinish={handleFinish}>
        <Form.Item label="Логин" name="login">
          <Input />
        </Form.Item>
        <Form.Item label="Пароль" name="password">
          <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
