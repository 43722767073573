import { LessonResponseDTO, UpsertLessonDTO } from './DTO';

import { APIFetch } from '../common/api-request';

async function readLessonById (id: number|string): Promise<LessonResponseDTO> {
  const response = await APIFetch({
    method: 'GET',
    path: 'lessons',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });

  return response.json();
}

async function readAllLessons (courseId: number|string): Promise<Array<LessonResponseDTO>> {
  const response = await APIFetch({
    method: 'GET',
    path: `lessons/all?courseId=${courseId}`,
    isAuthorized: true,
  });
  return response.json();
}

async function createLesson (body: UpsertLessonDTO): Promise<LessonResponseDTO> {
  const response = await APIFetch({
    method: 'POST',
    path: 'lessons',
    payload: body,
    isAuthorized: true,
  });
  return response.json();
}

async function updateLesson (id: number|string, body: UpsertLessonDTO): Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'lessons',
    queryParams: { id: id.toString() },
    payload: body,
    isAuthorized: true,
  });
}

async function deleteLesson (id: number|string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'lessons',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

export {
  readLessonById,
  readAllLessons,
  createLesson,
  updateLesson,
  deleteLesson
};
