import { DiscountDTO, CreateDiscountDTO } from './DTO';

import { APIFetch } from '../common/api-request';


async function getAllDiscounts (): Promise<Array<DiscountDTO>> {
  const discounts = await APIFetch({
    method: 'GET',
    path: 'discounts/all',
    isAuthorized: true,
  });
  return discounts.json();
}

async function getDiscountById (id: number | string): Promise<DiscountDTO> {
  const response = await APIFetch({
    method: 'GET',
    path: 'discounts',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
  return response.json();
}

async function createDiscount (body: CreateDiscountDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'discounts',
    payload: body,
    isAuthorized: true,
  });
}


export {
  getAllDiscounts,
  getDiscountById,
  createDiscount,
};
