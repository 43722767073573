import { useParams } from 'react-router-dom';
import { BaseQueryDTO } from '../../../api/DTO';
import { UpsertMailing } from './upsert-mailing.component';

const UpdateMailingController = () => {
  const { id } = useParams<BaseQueryDTO>();
  return <UpsertMailing id={ id } />
}

const CreateMailingController = () => {
  return <UpsertMailing />
}

export { UpdateMailingController, CreateMailingController };
