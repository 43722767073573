import { QuillInput } from '../quill-input/quill-input'
import { useEffect, useState } from 'react'

interface PageEditorProps {
  body: string;
  onChange: (body: string) => void;
  label: string;
}

export const PageEditorQuill = ({ body, onChange, label }: PageEditorProps) => {

  const [value, setValue] = useState('')

  useEffect(() => {
    onChange(value)
  }, [value, onChange])

  return (
    <div>
      {label}:
      <QuillInput
        defaultValue={body}
        setData={setValue}
      />
    </div>
  )
}
