import { Avatar, Collapse, Divider } from 'antd';
import { useEffect, useState } from 'react';
import ReactCountryFlag from "react-country-flag"
import { getCollectiveAdLeads } from '../../../../../api/collective-ads.api';
import { CollectiveAdDistributionDTO } from '../../../../../types';

interface Props {
	collectiveAdId: number;
}


const CollectiveAdLeads = ({ collectiveAdId }: Props): JSX.Element | null => {
	const { Panel } = Collapse;
	const [leadsDistribution, setLeadsDistribution] = useState<Array<CollectiveAdDistributionDTO>>();
	const noData = !leadsDistribution || !(Array.isArray(leadsDistribution) && leadsDistribution.length > 0);

	useEffect(() => {
		if (collectiveAdId) {
			getCollectiveAdLeads(collectiveAdId)
				.then((e) => setLeadsDistribution(e))
		}
	}, [collectiveAdId]);


	const ConsultantLeads = ({ id, firstName, lastName, avatarUrl, usersCount, users }: CollectiveAdDistributionDTO) => {
		const header = <div><Avatar src={avatarUrl} /> {firstName} {lastName}, лидов: {usersCount}</div>;
		const getName = (firstName: string, lastName?: string) => lastName ? `${firstName} ${lastName}` : `${firstName}`;

		return (
			<Panel header={header} key={id}>
				{users.map(({ id, firstName, lastName, country }, i) =>
					<p key={id}>{i + 1}. {getName(firstName, lastName)} <ReactCountryFlag countryCode={country} /></p>)}
			</Panel>
		)
	}

	if (noData) return null;

	return (
		<>
			<Divider style={{ marginTop: '30px' }} orientation="left">Сгенерированные лиды по консультантам</Divider>
			<Collapse>
				{leadsDistribution.map((c) => ConsultantLeads(c))}
			</Collapse>
		</>
	);
};

export { CollectiveAdLeads };
