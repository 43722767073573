import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { createCollectiveAd } from '../../../../api/collective-ads.api';
import { readAllMailings } from '../../../../api/mailings.api';
import { PageEditorQuill } from '../../../../components';
import { MailingResponseDTO } from '../../../../types';


const CreateCollectiveAdForm = (): JSX.Element => {
	const { Option } = Select;
  const [mailings, setMailings] = useState<Array<MailingResponseDTO>>();
	const [form] = Form.useForm();
	const [note, setNote] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [paymentMethod, setPaymentMethod] = useState<string>('');
	const history = useHistory();
	
	const handleCreate = () => {
    const { name, startDate, endDate, mailingId } = form.getFieldsValue();

    if (name && startDate && endDate && mailingId && paymentMethod && description) {
      const collectiveAd = {
        name,
        startDate,
        endDate,
				mailingId,
				description,
				note,
				paymentMethod,
      }

      createCollectiveAd(collectiveAd).then(() => {
        history.push('/collective-ads');
      });
    }
	}
	
	useEffect(() => {
		readAllMailings().then((mailings) => setMailings(mailings));
	}, [])

	return (
		<>
			<h1>Создание коллективной рекламы</h1>
			<Form form={form}>
				<Form.Item
					label="Название"
					name="name"
					wrapperCol={{ span: 10 }}
					rules={[{ required: true, message: 'Название коллективной рекламы должно быть заполнено!' }]}
				>
					<Input placeholder="Название коллективной рекламы" autoFocus />
				</Form.Item>
				<Form.Item
					required
					name="description"
					rules={[{ required: true, message: 'Описание коллективной рекламы должно быть заполнено!' }]}
				>
					<PageEditorQuill
						body={description}
						onChange={(body) => setDescription(body)}
						label="Описание"
					/>
				</Form.Item>
				<Form.Item>
					<PageEditorQuill
						body={note}
						onChange={(body) => setNote(body)}
						label="Заметка"
					/>
				</Form.Item>
				<Form.Item
					required
					name="paymentMethod"
					rules={[{ required: true, message: 'Способ оплаты коллективной рекламы должен быть заполнен!' }]}
				>
					<PageEditorQuill
						body={paymentMethod}
						onChange={(body) => setPaymentMethod(body)}
						label="Способ оплаты"
					/>
				</Form.Item>
				<Form.Item
					label="Дата начала"
					name="startDate"
					rules={[{ required: true, message: 'Начало коллективной рекламы должно быть указано!' }]}
				>
					<DatePicker format={'DD/MM/YYYY'} />
				</Form.Item>
				<Form.Item
					label="Дата конца"
					name="endDate"
					rules={[{ required: true, message: 'Конец коллективной рекламы должен быть указан!' }]}
				>
					<DatePicker format={'DD/MM/YYYY'} />
				</Form.Item>
				{mailings && (
				<Form.Item
					label="Рассылка"
					name="mailingId"
					rules={[{ required: true, message: 'Рассылка коллективной рекламы должна быть указана!' }]}
				>
					<Select placeholder="Рассылка" style={{ width: 500 }}>
						{mailings.map(({ id, title }) => <Option key={id} value={id}>{title}</Option>)}
					</Select>
				</Form.Item>
				)}
				<Button onClick={handleCreate} htmlType="submit">Создать</Button>
			</Form>
		</>
	);
}



export { CreateCollectiveAdForm };
