import { useParams } from 'react-router-dom';

import { BaseQueryDTO } from '../../../api/DTO';
import { UpsertTariff } from './upsert-tariff.component';

const UpdateTariffController = () => {
  const { id } = useParams<BaseQueryDTO>();
  return <UpsertTariff id={ id }/>
}

const CreateTariffController = () => <UpsertTariff />;

export { UpdateTariffController, CreateTariffController };
