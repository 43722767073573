import { useEffect, useState } from 'react';
import { Avatar, Button, Col, Divider, Input, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
	addConsultantToCollectiveAd, approveApplication, deleteConsultantFromCollectiveAd,
	getCollectiveAdApplications,
	getConsultantByEmailOrLogin, getConsultantsByCollectiveAd, rejectApplication
} from '../../../../../api/collective-ads.api';
import { AddConsultantToCollectiveAdDTO, CollectiveAdApplicant, ConsultantDTO } from '../../../../../types/collective-ads';

interface Props {
	collectiveAdId: number;
}


const CollectiveAdConsultants = ({ collectiveAdId }: Props): JSX.Element => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [consultant, setConsultant] = useState<ConsultantDTO>();
	const [consultants, setConsultants] = useState<Record<string, ConsultantDTO>[]>();
	const [applicants, setApplicants] = useState<CollectiveAdApplicant[]>();
	
	const ConsultantCard = (
		{ id, firstName, lastName, avatarUrl }: ConsultantDTO,
		added?: boolean,
		relationId?: number,
	) => (
		<Row key={id} gutter={16}> 
			<Col span={2}>
				<Avatar src={avatarUrl} shape="square" size="large" icon={<UserOutlined />} />
			</Col>
			<Col span={18} style={{ lineHeight: '40px' }}>
				<p>{firstName} {lastName}</p>
			</Col>
			<Col span={4}>
				{added && relationId ? 
					<Button onClick={() => handleConsultantDelete(relationId)} htmlType="button">Удалить</Button> :
					<Button onClick={() => handleAddConsultant(id)} htmlType="button">Добавить</Button>
				}
			</Col>
		</Row>
	);

	const ApplicantCard = (
		{ id, firstName, lastName, avatarUrl }: CollectiveAdApplicant,
	) => (
		<Row key={id} gutter={16}> 
			<Col span={2}>
				<Avatar src={avatarUrl} shape="square" size="large" icon={<UserOutlined />} />
			</Col>
			<Col xl={16} md={14} sm={10} xs={10} style={{ lineHeight: '40px' }}>
				<p>{firstName} {lastName}</p>
			</Col>
			<Col xl={6} md={8} sm={12} xs={12}>
				<Row gutter={6}>
					<Col>
						<Button onClick={() => handleApproveApplicant(id)} htmlType="button">Принять</Button>
					</Col>
					<Col>
						<Button onClick={() => handleRejectApplicant(id)} htmlType="button">Отклонить</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);


	useEffect(() => {
		if (collectiveAdId) {
			getConsultantsByCollectiveAd(collectiveAdId)
				.then((consultants) => setConsultants(consultants as Array<Record<string, ConsultantDTO>>));
			getCollectiveAdApplications(collectiveAdId).then((applicants) => setApplicants(applicants));
		}
	}, [collectiveAdId])


	const handleFindConsultant = () => {
		getConsultantByEmailOrLogin(searchValue)
			.then((consultant) => setConsultant(consultant));
	}

	const handleSearchInputChange = (e: any) => {
		const value = e.target.value.trim();
		setSearchValue(value);
	}

	const handleAddConsultant = (id: number) => {
		if (collectiveAdId) {
			const reqBody: AddConsultantToCollectiveAdDTO = {
				consultantId: id,
				collectiveAdId: collectiveAdId,
			}
			addConsultantToCollectiveAd(reqBody)
				.then((consultant: any) => {
					if (consultant.status >= 400) return;
					const newConsultants = consultants ? [...consultants, consultant] : consultant;
					setConsultants(newConsultants);
				});
		}
	}

	const handleApproveApplicant = (id: number) => {
		approveApplication(id);
	}

	const handleRejectApplicant = (id: number) => {
		rejectApplication(id);
	}

	const handleConsultantDelete = (id: number) => {
		deleteConsultantFromCollectiveAd(id);
	}

	return (
		<>
			<Divider style={{ marginTop: '30px' }} orientation="left">Добавление консультанта</Divider>
			<Row gutter={16}> 
				<Col span={16}>
					<Input onChange={handleSearchInputChange} placeholder="Логин или email консультанта" />
				</Col>
				<Col span={8}>
					<Button htmlType="button" onClick={handleFindConsultant}>Найти</Button>
				</Col>
			</Row>
			<br />
			{consultant && ConsultantCard(consultant)}

			{Array.isArray(consultants) && consultants.length > 0 && (
				<>
					<Divider orientation="left">Привязанные консультанты</Divider>
					{consultants.map((c) => ConsultantCard(c.consultant as ConsultantDTO, true, c.id as unknown as number))}
				</>
			)}
			{Array.isArray(applicants) && applicants.length > 0 && (
				<>
					<Divider orientation="left">Подавшие заявку на участие консультанты</Divider>
					{applicants.map((c) => ApplicantCard(c))}
				</>
			)}
		</>
	);
}

export { CollectiveAdConsultants };
