import { Button, Table } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
interface DataModel {
    title: string;
    dataIndex: string;
}
interface DataListProps {
    title: string;
    dataModel: Array<DataModel>;
    data: Array<any>
    onCreateButtonClick: () => void;
    onDeleteButtonClick?: (id: number) => void;
    onEditButtonClick: (id: number) => void;
    onCopyButtonClick?: (record: any) => void;
    canCreate?: boolean;
    itemsEditable?: boolean;
    itemsDeletable?: boolean;
}


export const DataList = (props: DataListProps) => {
    const { title, dataModel, data, itemsDeletable = true, itemsEditable = true,
        canCreate = true, onCreateButtonClick, onEditButtonClick,
        onDeleteButtonClick, onCopyButtonClick } = props;
    const ActionButtonsRender = (text: any, record: any): JSX.Element => (
        <>
            { itemsEditable && <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onEditButtonClick(record.id)}
            />
            }
            { !record.isSystem && itemsDeletable && onDeleteButtonClick &&
                <Button
                    shape="circle"
                    icon={<DeleteOutlined/>}
                    onClick={() => onDeleteButtonClick(record.id)}
                />
            }
            { onCopyButtonClick && <Button
                shape="circle"
                icon={<CopyOutlined />}
                onClick={() => onCopyButtonClick(record)}
            />

            }
        </>
    );

    const columns = [ ...dataModel, {
        title: 'Действия',
        dataIndex: 'actions',
        render: ActionButtonsRender,
    }].map(item => ({ ...item, key: item.dataIndex }));

    const dataSource = data.map(item => ({ ...item, key: item.id }));

    return (
        <>
            <div className="pages-list-header">
                <h2>{title}</h2>
                {canCreate && 
                    <p>
                        <Button
                            icon={<PlusCircleOutlined />}
                            onClick={onCreateButtonClick}
                        >
                            Создать
                        </Button>
                    </p>
                }
            </div>
            <Table columns={columns} dataSource={dataSource} />
        </>
    );
}
