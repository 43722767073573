import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Switch } from 'antd';

import { readPageById, createPage, updatePage } from '../../../api/pages.api';
import { Page } from '../../../types';

interface UpsertPageProps {
    pageId?: string;
}

export const UpsertPage = ({ pageId }: UpsertPageProps) => {
    const history = useHistory();
    const [page, setPage] = useState<Page>();
    const [form] = Form.useForm();

    useEffect(() => {
        if (pageId) {
            readPageById(pageId).then(body => {
                setPage(body);
                form.setFieldsValue(body);
            });
        }
    }, [setPage, pageId, form]);

    const handleUpdatePageClick = (): void => {
        if (page) {
            updatePage(page.id.toString(), form.getFieldsValue()).then();
        }
    }

    const handleCreatePageClick = (): void => {
        createPage(form.getFieldsValue()).then();
        history.push('/pages');
    }

    return (
        <>
        { pageId ? <h1>Редактирование страницы</h1> : <h1>Создание страницы</h1> }
            <Form form={form}>
                <Form.Item label="Ключ" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Имя" name="verboseName">
                    <Input />
                </Form.Item>
                <Form.Item label="Общедоступная" name="isFree" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label="Видимость" name="isPublic" valuePropName="checked">
                    <Switch />
                </Form.Item>
                { pageId ?
                    <Button onClick={handleUpdatePageClick}>
                        Обновить
                    </Button> :
                    <Button onClick={handleCreatePageClick}>
                        Создать
                    </Button>
                }
            </Form>
        </>
    )
}
