import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export const UploadFile = () => {
  return (
    <>
      <h1>Загрузка файлов</h1>
      <Upload
        name="file"
        action={`${process.env.REACT_APP_BOT_URL}/files`}
      >
        <Button icon={<UploadOutlined />}>Загрузить</Button>
      </Upload>
    </>
  );
}
