import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  ReadOutlined, VideoCameraOutlined,
  MailOutlined, SendOutlined, DollarOutlined,
  PercentageOutlined, FileOutlined, HistoryOutlined, FundViewOutlined,
} from '@ant-design/icons';

import 'antd/dist/antd.min.css';


export const SiderMenu = () => {
  const items = [
    {
      key: 1,
      label: <Link to="/pages">Страницы</Link>,
      icon: <ReadOutlined />,
    },
    {
      key: 2,
      label: <Link to="/courses">Курсы</Link>,
      icon: <VideoCameraOutlined />,
    },
    {
      key: 3,
      label: <Link to="/mailings">Рассылки</Link>,
      icon: <MailOutlined />,
    },
    {
      key: 4,
      label: <Link to="/files">Файлы</Link>,
      icon: <FileOutlined />,
    },
    {
      key: 5,
      label: <Link to="/instant-messages">Сообщения</Link>,
      icon: <SendOutlined />,
    },
    {
      key: 6,
      label: <Link to="/tariffs">Тарифы</Link>,
      icon: <DollarOutlined />,
    },
    {
      key: 7,
      label: <Link to="/discounts">Промокоды</Link>,
      icon: <PercentageOutlined />,
    },
    {
      key: 8,
      label: <Link to="/subscriptions">Транзакции</Link>,
      icon: <HistoryOutlined />,
    },
    {
      key: 9,
      label: <Link to="/collective-ads">Реклама</Link>,
      icon: <FundViewOutlined />,
    }
  ];
  return (<Menu mode="inline" theme="dark" items={items} />);
}
