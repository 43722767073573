import { useEffect, useState } from 'react';
import { AlignType } from 'rc-table/lib/interface'
import { Divider, Table } from 'antd';
import { getCollectiveAdStatistics } from '../../../../../api/collective-ads.api';
import { CollectiveAdStatisticsDTO } from '../../../../../types/collective-ads';


interface Props {
	id: number | string;
}


const CollectiveAdStatistics = ({ id }: Props): JSX.Element | null => {
	const [statistics, setStatistics] = useState<CollectiveAdStatisticsDTO | null>();

	useEffect(() => {
		getCollectiveAdStatistics(id).then((statistics) => {
			const { registrationPriceInCents: price  } = statistics;
			const newStatistics = {
				...statistics,
				registrationPriceInCents: price ? Number((price / 100).toFixed(2)) : price,
			}
			setStatistics(newStatistics);
		});
	}, [id])


	const columns = [
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'Значение',
			dataIndex: 'value',
			key: 'value',
			align: 'right' as AlignType
		}
	];

	const descriptionMapper: Record<string, string> = {
		visits: 'Посетили сайт',
		registrations: 'Регистрации на курс',
		registrationsConversionPercent: 'Конверсия регистрации на курс, %',
		courseFinished: 'Закончили курс',
		courseFinishedConversionPercent: 'Конверсия закончивших курс, %',
		contactsSeen: 'Просмотрели контакты',
		consultationOrdered: 'Заказали консультацию',
		contactsSeenConversionPercent: 'Конверсия просмотр контактов, %',
		consultationOrderedConversionPercent: 'Конверсия заказали консультацию, %',
		registrationPriceInCents: 'Стоимость регистрации на курс ($)',
	 };

	 
	 if (statistics) {
		const data = Object.entries(statistics)
			.map(([description, value], i) => (
				{ key: i, description: descriptionMapper[description], value: value ? value : 0 }
			));
		return (
			<>
				<Divider orientation="left">Статистика</Divider>
				<Table columns={columns} dataSource={data} pagination={false} />
			</>
		)
	}

	return null;
}

export { CollectiveAdStatistics };
