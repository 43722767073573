import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';

import { readAllPages, deletePage } from '../../../api/pages.api';
import { DataList } from '../../../components';
import { PagesListAllResponseDTO } from '../../../types';

import './pages-list.style.css';

export const PagesList = () => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
    const [pages, setPages] = useState<Array<PagesListAllResponseDTO>>([]);
    const history = useHistory();

    const handleCreate = (): void => {
        history.push('/pages/create');
    }

    const handleDelete = (id: number): void => {
        deletePage(id).then(() =>
            setPages(pages?.filter(item => item.id !== id))
        );
    }

    const handleEdit = (id: number): void => {
        history.push(`pages/${id}`);
    }

    const showModal = () => {
        setIsModalVisible(true);
      };
      
    const handleOk = () => {
        setIsModalVisible(false);
        if (selectedItemId) {
            handleDelete(selectedItemId);
        }
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const dataModel = [
        { title: 'ID', dataIndex: 'id' },
        { title: 'Ключ', dataIndex: 'name' },
        { title: 'Название', dataIndex: 'verboseName' }
    ];

    useEffect(() => {
        readAllPages()
            .then(pageResponse => {
                const dataSource = pageResponse
                    .map(item => ({ ...item, key: item.id }));
                setPages(dataSource);
            });
    }, [ setPages ]);

    return (
        <>
            <DataList
                title="Страницы"
                dataModel={dataModel}
                data={pages}
                onCreateButtonClick={() => handleCreate()}
                onDeleteButtonClick={(id) => {
                    showModal();
                    setSelectedItemId(id);
                }}
                onEditButtonClick={(id) => handleEdit(id)}
            />
            <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Вы действительно хотите удалить эту страницу?</p>
            </Modal>
        </>
    );
}
