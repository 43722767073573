import { BaseProps } from '../../../common/interfaces/base.props';
import { CreateCollectiveAdForm } from './create-collective-ad-form';
import { EditCollectiveAdForm } from './edit-collective-ad-form';


export const UpsertCollectiveAd = ({ id }: BaseProps) => {
  const editMode = id ? true : false;


  const CreateCollectiveAd = <CreateCollectiveAdForm />;
  const EditCollectiveAd = 
    (
      <>
        {id && <EditCollectiveAdForm id={id} />}
      </>
    );
  
  
  return editMode ? EditCollectiveAd : CreateCollectiveAd;
}
