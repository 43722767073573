import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseQueryDTO } from '../../../api/DTO';
import { UpsertPage } from './upsert-page.component';
import { PageTranslationsList } from '../page-translations/page-translations-list/page-translations-list';
import { SubpagesList } from '../subpages/subpages-list/subpages-list.component';
import { readPageById } from '../../../api/pages.api';
import { Page } from '../../../types';


const CreatePageController = () => <UpsertPage />;

const UpdatePageController = () => {
	const [page, setPage] = useState<Page | undefined>(undefined);
	const { id } = useParams<BaseQueryDTO>();

	useEffect(() => {
		if (id) readPageById(id).then(setPage);
	}, [id]);

	return (
		<>
			<UpsertPage pageId={id} />
			{id && page?.pageTranslations &&
				<PageTranslationsList pageId={id} translations={page.pageTranslations} />}
			{id && <SubpagesList />}
		</>
	);
}

export { CreatePageController, UpdatePageController };
