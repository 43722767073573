import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import objectSupport from 'dayjs/plugin/objectSupport';
import { SystemTimeDTO } from '../../api/DTO';
import './ServerTime.css';
var dayjs = require("dayjs")
dayjs.extend(objectSupport);

interface Props {
	timeObj: SystemTimeDTO;
}
export const ServerTime = ({ timeObj }: Props) => {
	const [time, setTime] = useState<Dayjs>(dayjs(timeObj));

	useEffect(() => {
		const timerId = setInterval(() => {
			const newTime = time.add({ seconds: 1 });
			setTime(newTime);
		}, 1000);
    return () => clearTimeout(timerId);
	}, [time]);

	return <p>Время сервера: {time.format('HH:mm:ss DD/MM/YYYY')}</p>;
}