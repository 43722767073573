import { APIFetch } from '../common/api-request';
import { SendInstantMessageDTO } from './DTO';
import { MailingResponseDTO, UpsertMailingDTO } from '../types';

async function readAllMailings (): Promise<Array<MailingResponseDTO>> {
  const mailings = await APIFetch({
    method: 'GET',
    path: 'mailings/all',
    isAuthorized: true,
  });
  return mailings.json();
}

async function readMailingById (id: number | string): Promise<MailingResponseDTO> {
  const response = await APIFetch({
    method: 'GET',
    path: 'mailings',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
  return response.json();
}

async function createMailing (body: UpsertMailingDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'mailings',
    payload: body,
    isAuthorized: true,
  });
}

async function updateMailing (id: number | string, body: UpsertMailingDTO): Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'mailings',
    queryParams: { id: id.toString() },
    payload: body,
    isAuthorized: true,
  })
}

async function deleteMailing (id: number | string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'mailings',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

async function sendInstantMessage (body: SendInstantMessageDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'mailings/send-instant-message',
    payload: body,
    isAuthorized: true,
  });
}

async function readLandingInfo (mailingId: number | string): Promise<any> {
  const response = await APIFetch({
    method: 'GET',
    path: 'mailings/landing',
    queryParams: { mailingId: mailingId.toString(), key: '' },
    isAuthorized: true,
  });
  return response.json();
}

async function updateLandingInfo (mailingId: number | string, body: any): Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'mailings/landing',
    queryParams: { mailingId: mailingId.toString(), },
    payload: body,
    isAuthorized: true,
  });
}

export {
  readAllMailings,
  readMailingById,
  createMailing,
  updateMailing,
  deleteMailing,
  sendInstantMessage,
  readLandingInfo,
  updateLandingInfo
};
