import { useEffect, useState } from 'react';
import { Button, Collapse, Form, Modal, Select, Switch } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { MessageGroupForm } from './interfaces/message-group-form.interface';
import { Message } from '../message/message.component';
import { MessageTypeEnum } from '../../../enums/message-type';
import { MessageResponseDTO, SystemTimeDTO, TgMessageDTO } from '../../../api/DTO';
import { updateMessage } from '../../../api/messages.api';
import { readAllLessons } from '../../../api/lessons.api';
import { getSystemTime } from '../../../api/system.api';
import { MessageGroupLessonItem } from './interfaces/message-group-lesson-item.interface';
import { TimePicker, ServerTime } from '../../../components';


export type MessageGroupProps = {
  message: MessageResponseDTO;
  courseId: number;
}


export const MessageGroup = ({ message: msg, courseId }: MessageGroupProps) => {
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [systemTime, setSystemTime] = useState<SystemTimeDTO>();
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [lessonsList, setLessonsList] = useState<Array<MessageGroupLessonItem> | undefined>();
  const [form] = Form.useForm<MessageGroupForm>();
  const [message, setMessage] = useState(msg);

  useEffect(() => {
    form.setFieldsValue({
      lessonId: message?.lesson?.id,
      delaySeconds: message.delaySeconds,
      fromDayStart: message.fromDayStart,
    });
  });

  useEffect(() => {
    readAllLessons(courseId).then((lessons) => {
      setLessonsList(lessons);
    });
  }, [courseId]);

  useEffect(() => {
    getSystemTime().then((time) => setSystemTime(time));
  }, [])

  const handleAddMessage = () => {
    const newTgMessage = {
      type: MessageTypeEnum.TEXT,
      payload: undefined
    };
    const newMessage = {
      ...message, telegramMessage: [ ...message.telegramMessage, newTgMessage ]
    };
    setMessage(newMessage);
  }

  const handleChangeMessage = (tgMessage: Partial<TgMessageDTO>, i: number) => {
    const newMessage = { ...message };
    newMessage.telegramMessage[i] = { ...newMessage.telegramMessage[i], ...tgMessage }
    setMessage(newMessage);
  }

  const handleSaveMessageGroup = () => {
    const { id, ...messageToSend } = { ...message, ...form.getFieldsValue() };
    updateMessage(id, messageToSend).then();
  }

  const handleDeleteTgMessage = (i: number) => {
    const newMessage = { ...message };
    message.telegramMessage.splice(i, 1);

    setMessage(newMessage);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDeleteTgMessage(selectedItemId);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Form form={form}>
        <Form.Item label="Урок" name="lessonId">
          <Select placeholder="Урок" style={{ width: 500 }}>
            {lessonsList && lessonsList.map(({ id, title }: MessageGroupLessonItem) =>
              (<Option key={id} value={id}>{title}</Option>)
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Отправка через" name="delaySeconds">
          <TimePicker showDays={true} />
        </Form.Item>
        {systemTime && <ServerTime timeObj={systemTime} />}
        <Form.Item label="Отсчитывать с начала суток" name="fromDayStart" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
      <Collapse>
      {message.telegramMessage.map((tgMessage, i) =>
        <Collapse.Panel key={i} header="Сообщение" extra={[
          <Button
            key={`btn${i}`}
            icon={<DeleteOutlined />}
            type="text"
            onClick={() => {
              showModal();
              setSelectedItemId(i);
            }}
          />
        ]}>
          <Message tgMessage={tgMessage} onChange={(tgMessage) => handleChangeMessage(tgMessage, i)} />
        </Collapse.Panel>
      )}
      </Collapse>
      <Button onClick={() => handleAddMessage()}>Добавить сообщение</Button>
      <Button onClick={() => handleSaveMessageGroup()}>Сохранить</Button>
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить это сообщение?</p>
      </Modal>
    </>
  );
}
