
import { APIFetch } from '../common/api-request';
import { CourseResponseDTO, UpsertCourseDTO } from '../types';

async function readCourseById (id: number | string): Promise<CourseResponseDTO> {
  const course = await APIFetch({
    method: 'GET',
    path: 'courses',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
  return course.json();
}

async function readAllCourses (): Promise<Array<CourseResponseDTO>> {
  const response = await APIFetch({
    method: 'GET',
    path: 'courses/all',
    isAuthorized: true,
  });
  return response.json();
}

async function createCourse (body: UpsertCourseDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'courses',
    payload: body,
    isAuthorized: true,
  });
}

async function updateCourse (id: number | string, body: UpsertCourseDTO):Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'courses',
    queryParams: { id: id.toString() },
    payload: body,
    isAuthorized: true,
  });
}

async function deleteCourse (id: number | string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'courses',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

export {
  readAllCourses,
  readCourseById,
  createCourse,
  updateCourse,
  deleteCourse
};
