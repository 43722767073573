import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, Input, Select } from 'antd';

import { readPageTranslationsById, createPageTranslations, updatePageTranslations } from '../../../../../api/pages.api';
import { PageTranslation } from '../../../../../types';
import { Language, languageGlossary } from '../../../../../enums/languages';
import { BaseQueryDTO } from '../../../../../api/DTO';
import { SubpageTranslationContent } from '../subpage-translation-content/subpage-translation-content';


interface CreateSubpageTranslationsProps {
	subpageId?: string;
	pageTranslationId?: string;
}

export const CreatePageTranslations = ({ subpageId, pageTranslationId }: CreateSubpageTranslationsProps) => {
	const { Option } = Select;
	const history = useHistory();
	const { id: pageId }  = useParams<Record<string, string>>();
	const [form] = Form.useForm();

	useEffect(() => {
		if (pageTranslationId) {
			readPageTranslationsById(pageTranslationId).then(body => {
				form.setFieldsValue(body);
			});
		}
	}, [pageTranslationId, form]);

	const handleUpdatePageTranslationsClick = (): void => {
		if (pageTranslationId) {
			updatePageTranslations(pageTranslationId, form.getFieldsValue()).then();
		}
	}

	const handleCreatePageTranslationsClick = (): void => {
		const formValues = form.getFieldsValue();
		createPageTranslations({ ...formValues, pageId: Number(subpageId)}).then();
		history.push(`/pages/${pageId}/subpages/${subpageId}`);
	}

	return (
		<>
			{ pageTranslationId ? <h1>Редактирование перевода подстраницы</h1> : <h1>Создание перевода подстраницы</h1> }
			<Form form={form}>
				<Form.Item
					label="Перевод"
					name="language"
					required
				>
					<Select placeholder="Выберите язык">
						{languageGlossary.map((language: Language) => (
							<Option key={language.id} value={language.isoCode}>
								{language.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item required label="Имя" name="name">
					<Input />
				</Form.Item>
				{ pageTranslationId ?
					<Button onClick={handleUpdatePageTranslationsClick}>
						Обновить
					</Button> :
					<Button onClick={handleCreatePageTranslationsClick}>
						Создать
					</Button>
				}
			</Form>
		</>
	)
}

const CreateSubpageTranslationsController = () => {
	const { subpageId }  = useParams<Record<string, string>>();

	return <CreatePageTranslations subpageId={subpageId} />;
};

const UpdateSubpageTranslationsController = () => {
	const [pageTranslations, setPageTranslations] = useState<PageTranslation | undefined>(undefined);
	const { id }  = useParams<BaseQueryDTO>();

	useEffect(() => {
		if (id) readPageTranslationsById(id).then(setPageTranslations);
	}, [id]);

	return (
		<>
			<CreatePageTranslations subpageId={id} pageTranslationId={id} />
			{id && pageTranslations?.pageViews &&
				<SubpageTranslationContent pageTranslationId={id} pageViews={pageTranslations?.pageViews} />}
		</>
	);
}

export { CreateSubpageTranslationsController, UpdateSubpageTranslationsController };
