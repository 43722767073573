import {
  AddConsultantToCollectiveAdDTO, CollectiveAdDTO,
  CreateCollectiveAdDTO, UpdateCollectiveAdDTO,
  CollectiveAdStatisticsDTO,
  ConsultantDTO,
  CollectiveAdDistributionDTO,
  CollectiveAdApplicant,
} from '../types/collective-ads';
import { APIFetch } from '../common/api-request';


async function getCollectiveAdById (id: number | string): Promise<CollectiveAdDTO> {
  const collectiveAd = await APIFetch({
    method: 'GET',
    path: 'collective-ads',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
  return collectiveAd.json();
}

async function getAllCollectiveAds (): Promise<Array<CollectiveAdDTO>> {
  const response = await APIFetch({
    method: 'GET',
    path: 'collective-ads/all',
    isAuthorized: true,
  });
  return response.json();
}

async function createCollectiveAd (body: CreateCollectiveAdDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'collective-ads',
    payload: body,
    isAuthorized: true,
  });
}

async function updateCollectiveAd (id: number | string, body: UpdateCollectiveAdDTO):Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'collective-ads',
    queryParams: { id: id.toString() },
    payload: body,
    isAuthorized: true,
  });
}


async function getConsultantsByCollectiveAd (id: number | string): Promise<Array<Record<string, ConsultantDTO>>> {
  const consultant = await APIFetch({
    method: 'GET',
    path: 'collective-ad-consultants/by-collective-add',
    queryParams: { collectiveAdId: id.toString() },
    isAuthorized: true,
  });
  return consultant.json();
}


async function getConsultantByEmailOrLogin (loginOrEmail: string): Promise<ConsultantDTO> {
  const consultant = await APIFetch({
    method: 'GET',
    path: 'consultants/find',
    queryParams: { loginOrEmail },
    isAuthorized: true,
  });
  return consultant.json();
}


async function addConsultantToCollectiveAd (body: AddConsultantToCollectiveAdDTO): Promise<ConsultantDTO> {
  const consultant = await APIFetch({
    method: 'POST',
    path: 'collective-ad-consultants/assign-consultant',
    payload: body,
    isAuthorized: true,
  });
  return consultant.json();
}


async function deleteConsultantFromCollectiveAd (id: number | string):Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'collective-ad-consultants',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}


async function getCollectiveAdStatistics (id: number | string): Promise<CollectiveAdStatisticsDTO> {
  const response = await APIFetch({
    method: 'GET',
    path: 'collective-ads/statistics',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
  return response.json();
}

async function getCollectiveAdLeads (id: number | string): Promise<CollectiveAdDistributionDTO[]> {
  const response = await APIFetch({
    method: 'GET',
    path: 'collective-ad-consultants/distribution',
    queryParams: { collectiveAdId: id.toString() },
    isAuthorized: true,
  });
  return response.json();
}


async function getCollectiveAdApplications (id: number | string): Promise<CollectiveAdApplicant[]> {
  const response = await APIFetch({
    method: 'GET',
    path: 'collective-ad-consultants/applications',
    queryParams: { collectiveAdId: id.toString()},
    isAuthorized: true,
  });
  return response.json();
}

async function approveApplication (id: number | string): Promise<void> {
  await APIFetch({
    method: 'PATCH',
    path: 'collective-ad-consultants/approve',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

async function rejectApplication (id: number | string): Promise<void> {
  await APIFetch({
    method: 'PATCH',
    path: 'collective-ad-consultants/reject',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}


export {
	getCollectiveAdById,
	getAllCollectiveAds,
	createCollectiveAd,
  updateCollectiveAd,
  getConsultantsByCollectiveAd,
  getConsultantByEmailOrLogin,
  addConsultantToCollectiveAd,
  deleteConsultantFromCollectiveAd,
  getCollectiveAdStatistics,
  getCollectiveAdLeads,
  getCollectiveAdApplications,
  approveApplication,
  rejectApplication,
};
