import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {Button, Form, Input, InputNumber, Space, Switch} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { readLessonById, createLesson, updateLesson } from '../../../api/lessons.api';
import { LessonResponseDTO } from '../../../api/DTO';
import { TimePicker, PageEditorQuill } from '../../../components';

interface Props {
    id?: string | null;
    courseId?: string | null;
}

export const UpsertLesson = ({ id, courseId }: Props) => {
    const [lesson, setLesson] = useState<LessonResponseDTO>();
    const [videoDescription, setVideoDescription] = useState<string>('');
    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            readLessonById(id).then(data => {
                setLesson(data);
                setVideoDescription(data.body);
                form.setFieldsValue(data);
            });
        }
    }, [setLesson, setVideoDescription, form, id]);

    const handleSubmit = (): void => {
        const formData = { ...form.getFieldsValue(), body: videoDescription }
        if (id) {
            updateLesson(id, formData)
                .then();
        } else {
            createLesson({ ...formData, courseId: parseInt(courseId as string, 10) }).then();
        }
        history.goBack();
    }


    return (
        <>
            { id ? <h1>Редактирование урока</h1> : <h1>Создание урока</h1> }
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item label="Подзаголовок" name="subtitle">
                    <Input />
                </Form.Item>
                <Form.Item label="Заголовок" name="title">
                    <Input />
                </Form.Item>
                <Form.Item label="Рейтинг урока" name="lessonRank">
                    <InputNumber disabled />
                </Form.Item>
                <Form.Item label="Порядок урока" name="lessonOrder">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Показывать важные ссылки" name="showSignature" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label="Ссылка на видео" name="videoUrl">
                    <Input />
                </Form.Item>
                <Form.Item>
                    { (lesson?.body !== undefined || !id)  &&
                        <PageEditorQuill
                            body={lesson?.body ?? videoDescription}
                            onChange={setVideoDescription}
                            label="Описание урока"
                        />
                    }
                </Form.Item>

                <h3>Тайминг</h3>
                <Form.List name="chapters" initialValue={[]}>
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                        <Space size="large" key={key}>
                            <Form.Item
                                {...restField}
                                name={[name, 'secondsPast']}
                                rules={[{ required: true, message: 'Нужно заполнить время' }]}
                            >
                            <TimePicker showDays={false} />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'description']}
                                rules={[{ required: true, message: 'Нужно заполнить событие' }]}
                            >
                            <Input placeholder="Событие"/>
                            </Form.Item>
                            <Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Form.Item>
                        </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add field
                            </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                <h3>Подарок</h3>
                <Form.Item label="Подарок название" name="giftName">
                    <Input />
                </Form.Item>
                <Form.Item label="Ссылка для скачивания подарка" name="giftDownloadUrl">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
