import { useEffect, useState } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './wysiwyg-input.css';

interface WysiwygInputProps extends ReactQuillProps {
  setData: (body: string) => void;
  label: string;
}

export const WysiwygInput = ({ defaultValue, setData, label }: WysiwygInputProps) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setData(value as string);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const modulesTelegram = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link']
    ],
    
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: true,
    },
  }

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <label>
        {label}:
        <ReactQuill
          theme="snow"
          value={value}
          modules={modulesTelegram}
					onChange={handleChange}
        />
      </label>
    </>
  );
}
