import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { DataList } from '../../../components';
import { getAllCollectiveAds } from '../../../api/collective-ads.api';
import { CollectiveAdDTO } from '../../../types';


export const CollectiveAdsList = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [collectiveAds, setСollectiveAds] = useState<Array<CollectiveAdDTO>>([]);
  const history = useHistory();

  useEffect(() => {
    getAllCollectiveAds().then(ads => {
      const mappedCollectiveAds = ads.map((ad) => {
        return {
          ...ad,
          startDate: ad.startDate ? dayjs(ad.startDate).format('DD/MM/YYYY') : null,
          endDate: ad.startDate ? dayjs(ad.endDate).format('DD/MM/YYYY') : null,
          priceInCents: ad.priceInCents ? ad.priceInCents / 100 : null,
        }
      });
      setСollectiveAds(mappedCollectiveAds);
    });
  }, [ setСollectiveAds ]);

  const handleCreate = (): void => {
    history.push('/collective-ads/create')
  }

  const handleEdit = (id: number | string): void => {
    history.push(`/collective-ads/${id}`);
  }

  const handleDelete = (id: number | string): void => {
    console.log('Deleted', id);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDelete(selectedItemId);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dataModel = [
    { title: 'ID', dataIndex: 'id' },
    { title: 'Название', dataIndex: 'name' },
    { title: 'Дата начала', dataIndex: 'startDate' },
    { title: 'Дата конца', dataIndex: 'endDate'},
    { title: 'Бюджет', dataIndex: 'priceInCents'},
  ];

  return (
    <>
      <DataList
        title="Коллективная реклама"
        dataModel={dataModel}
        data={collectiveAds}
        itemsDeletable={false}
        onCreateButtonClick={() => handleCreate()}
        onDeleteButtonClick={(id) => {
          showModal();
          setSelectedItemId(id);
        }}
        onEditButtonClick={(id) => handleEdit(id)}
      />
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить эту коллективную рекламу?</p>
      </Modal>
    </>
  );
}
