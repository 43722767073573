export type Language = {
	id: number;
	isoCode: string;
	name: string;
}

export const languageGlossary: Language[] = [
	{
		id: 1,
		isoCode: 'en',
		name: 'English',
	},
	{
		id: 2,
		isoCode: 'ru',
		name: 'Russian',
	},
	{
		id: 3,
		isoCode: 'uk',
		name: 'Ukrainian',
	},
	{
		id: 4,
		isoCode: 'pt',
		name: 'Portuguese',
	},
	{
		id: 5,
		isoCode: 'es',
		name: 'Spanish',
	},
	{
		id: 6,
		isoCode: 'vi',
		name: 'Vietnamese',
	},
	{
		id: 7,
		isoCode: 'fr',
		name: 'French',
	},
	{
		id: 8,
		isoCode: 'ja',
		name: 'Japanese',
	},
	{
		id: 9,
		isoCode: 'tr',
		name: 'Turkish',
	},
	{
		id: 10,
		isoCode: 'ar',
		name: 'Arabic',
	},
	{
		id: 11,
		isoCode: 'id',
		name: 'Indonesian',
	},
	{
		id: 12,
		isoCode: 'de',
		name: 'German',
	},
	{
		id: 13,
		isoCode: 'it',
		name: 'Italian',
	},
	{
		id: 14,
		isoCode: 'th',
		name: 'Thai',
	},
	{
		id: 15,
		isoCode: 'ph',
		name: 'Filipino',
	},
	{
		id: 16,
		isoCode: 'ch',
		name: 'Chinese',
	},
	{
		id: 17,
		isoCode: 'ko',
		name: 'Korean',
	},
];
