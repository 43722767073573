import { useEffect, useState } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './quill-input.css';

interface QuillInputProps extends ReactQuillProps {
  setData: (body: string) => void;
}

// THIS COMPONENT IS NOT USED ANYMORE
export const QuillInput = ({ defaultValue, setData }: QuillInputProps) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setData(value as string)
  }, [value, setData]);


  const modules = {
    toolbar: [
      [
        { header: '1' },
        { header: '2' },
      ],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ font: [] }],
      // [{ size: [] }],
      [{ align: [] }],
      [
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [
        'link',
        'image',
        'video',
      ],
      [
        'clean',
      ],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }


  return <ReactQuill
    theme="snow"
    value={value}
    modules={modules}
    onChange={setValue}
  />
}


