import { CommentResponseDTO } from '../../api/DTO';
import { APIFetch } from '../../common/api-request';


async function getAllComments (): Promise<Array<CommentResponseDTO>> {
    const response = await APIFetch({
        method: 'GET',
        path: 'user-lesson/comment/all'
    });
    return response.json();
}

export { getAllComments };
