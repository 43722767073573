import { useEffect } from 'react';
import { Button, Form, Input, Row, Select, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { createCourse, readCourseById, updateCourse } from '../../../api/courses.api';
import { LessonsList } from '../lessons-list/lessons-list.component';
import { BaseProps } from '../../../common/interfaces/base.props';
import { ButtonsAvailableEnum } from '../../../enums/buttons-availabe';


export const UpsertCourse = ({ id }: BaseProps) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();

  useEffect(() => {
    if (id) {
      readCourseById(id)
        .then(form.setFieldsValue);
    }
  }, [ form.setFieldsValue, id ]);

  const handleCreate = () => {
    const courseData = { ...form.getFieldsValue(), isMailing: true };
    createCourse(courseData).then(() => {
      history.push('/courses');
    });
  }

  const handleUpdate = () => {
    if (id) {
      const courseData = { ...form.getFieldsValue(), isMailing: true };
      updateCourse(id, courseData).then(() => {
        history.push('/courses');
      });
    }
  }

  return (
    <>
      { id ? <h1>Редактирование курса</h1> : <h1>Создание курса</h1> }
      <Form form={form} >
      {!id &&
          <Form.Item label="Навигация" name="isNavigationAllowed">
            <Select placeholder="Навигация" style={{ width: 500 }}>
              <Option key={1} value={true}>Навигация разрешена</Option>
              <Option key={2} value={false}>Навигация запрещена</Option>
            </Select>
          </Form.Item>
        }
        <Form.Item label="Название" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Краткое описание" name="description">
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Отображать на страницах уроков" name="buttonsAvailable">
          <Select placeholder="Выберите вариант отображения уроков" style={{ width: 500 }}>
            <Option key={1} value={ButtonsAvailableEnum.TELEGRAM_AND_CONSULTATION}>СВЯЗАТЬСЯ В ТЕЛЕГРАМ + ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ</Option>
            <Option key={2} value={ButtonsAvailableEnum.CONSULTATION}>ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ</Option>
            <Option key={3} value={ButtonsAvailableEnum.TELEGRAM}>СВЯЗАТЬСЯ В ТЕЛЕГРАМ</Option>
          </Select>
        </Form.Item>

        <h3>Важные ссылки</h3>
        <Row justify="space-between">
          <div style={{ 'lineHeight': '32px' }}>Telegram канал:</div>
          <Form.Item label="Показывать поле" name="showChannelLink" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Row>

        <Row justify="space-between">
          <Form.Item
            label="Канал команды"
            name="teamChannelLink"
          >
            <Input
              placeholder="Введите название канала"
              style={{ 'minWidth': '270px' }}
            />
          </Form.Item>
          <Form.Item
            label="Показывать вместо авторского канала"
            name="teamChannelLinkPreferred"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Row>

        <Row justify="space-between">
          <Form.Item label="Регистрация в" name="registrationToCompanyLinkLabel">
            <Input placeholder="Введите название вашей компании" style={{ 'minWidth': '270px' }} />
          </Form.Item>
          <Form.Item label="Показывать поле" name="showRegistrationToCompanyLink" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Form.Item label="Активация системы" name="systemActivationLinkLabel">
            <Input placeholder="Введите название команды" style={{ 'minWidth': '270px' }} />
          </Form.Item>
          <Form.Item label="Показывать поле" name="showSystemActivationLink" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Row>
      </Form>
      { id ? <Button onClick={handleUpdate}>Обновить</Button> : <Button onClick={handleCreate}>Создать</Button> }
      { id && <LessonsList courseId={id} /> }
    </>
  );
}
