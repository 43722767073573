import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { readLandingInfo, updateLandingInfo } from '../../api/mailings.api';
import { BaseQueryDTO } from '../../api/DTO';


export const LandingInfo = () => {
  const { id } = useParams<BaseQueryDTO>();
  const [ form ] = Form.useForm();

  useEffect(() => {
    readLandingInfo(id)
      .then(landingInfo => {
        form.setFieldsValue({ data: JSON.stringify(landingInfo, undefined, 2), });
      });
  });

  const handleSave = () => {
    const data = JSON.parse(form.getFieldsValue().data);
    updateLandingInfo(id, data).then();
  }

  return (
    <Form form={form}>
      <Form.Item name="data">
        <Input.TextArea style={{ minHeight: 'calc(100vh - 100px)' }} />
      </Form.Item>
      <Button onClick={() => handleSave()}>Сохранить</Button>
    </Form>
  );

}
