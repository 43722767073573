import { APIFetch } from '../common/api-request';
import { PageContentRequestDTO, PageContentResponseDTO } from '../types';

async function deleteContent (id: number|string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'page-views',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

async function updateContent (id: number|string, body: PageContentRequestDTO): Promise<void> {
  await APIFetch({
    method: 'PUT',
    path: 'page-views',
    queryParams: { id: id.toString() },
    payload: body,
    isAuthorized: true,
  });
}

async function createContent (body: PageContentRequestDTO): Promise<PageContentResponseDTO> {
  const response = await APIFetch({
    method: 'POST',
    path: 'page-views',
    payload: body,
    isAuthorized: true,
  });
  return response.json();
}

export {
  createContent,
  updateContent,
  deleteContent
};
