import { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, InputNumber, Statistic } from 'antd';
import { useHistory } from 'react-router-dom';

import { getTariffById, createTariff, getTariffMinPrice } from '../../../api/tariffs.api';

import { BaseProps } from '../../../common/interfaces/base.props';


export const UpsertTariff = ({ id }: BaseProps) => {
  const [minPrice, setMinPrice] = useState<number | null>();
  const [form] = Form.useForm();
  const history = useHistory();

  const viewMode: boolean = id ? true : false;

  useEffect(() => {
    if (id) {
      getTariffById(id).then(data => {
        const displayData = {
          ...data,
          priceInCents: data.priceInCents / 100,
        }
        form.setFieldsValue(displayData);
      });
    }
  }, [form.setFieldsValue, form, id]);

  const onTariffDurationChange = (days: number) => {
    getTariffMinPrice(days).then((res) => setMinPrice(res.minPriceInCents / 100));
  };

  const handleCreate = () => {
    const { title, durationDays, priceInCents } = form.getFieldsValue();

    if (title && durationDays && priceInCents) {
      const tariff = {
        title,
        durationDays,
        priceInCents: priceInCents * 100,
      }

      createTariff(tariff).then(() => {
        history.push('/tariffs');
      });
    }
  }

  const handleUpdate = () => {
    if (id) {
      history.push('/tariffs');
    }
  }


  return (
    <>
      <h1>{id ? 'Просмотр тарифа' : 'Создание тарифа'}</h1>
      <Alert
        message="Вначале укажите длительность тарифа после чего будет посчитана соответствующая минимальная цена. Минимальная длительность тарифа - 90 дней."
        type="info"
        style={{ marginBottom: '20px' }}
      />
      <Form form={form}>
        <Form.Item
          label="Название"
          name="title"
          wrapperCol={{ span: 10 }}
          rules={[{ required: true, message: 'Название тарифа должно быть заполнено!' }]}
        >
          <Input placeholder="Название тарифа" autoFocus disabled={viewMode} />
        </Form.Item>
        <Form.Item
          label="Длительность тарифа (дней)"
          name="durationDays"
          rules={[{ required: true, message: 'Длительность тарифа должна быть указана!' }]}
        >
          <InputNumber
            controls={false}
            disabled={viewMode}
            placeholder="Дней"
            onChange={onTariffDurationChange}
            min={90}
          />
        </Form.Item>
        {minPrice && <Statistic prefix="$" title="Минимальная цена" value={minPrice} />}
        <Form.Item
          label="Цена, $"
          name="priceInCents"
          rules={[{ required: true, message: 'Стоимость тарифа должна быть указана!' }]}
        >
          <InputNumber
            controls={false}
            disabled={viewMode || !form.getFieldValue('durationDays')}
            placeholder="Цена, $"
            min={minPrice ?? 0}
          />
        </Form.Item>
      { id ?
        <Button onClick={handleUpdate}>Назад к списку</Button> :
        <Button onClick={handleCreate} htmlType="submit">Создать</Button>
      }
      </Form>
      {!id && (
        <Alert
            style={{ marginTop: '30px' }}
            message="Внимание!"
            description="Редактировать тариф после его создания будет невозможно!"
            type="warning"
            showIcon
          />
      )}
    </>
  );
}
