import moment from 'moment';
import { Button, DatePicker, Divider, Form, Input, InputNumber, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCollectiveAdById, updateCollectiveAd } from '../../../../api/collective-ads.api';
import { CollectiveAdConsultants } from './collective-ad-consultants';
import { CollectiveAdStatistics } from './collective-ad-statistics';
import { CollectiveAdLeads } from './collective-ad-leads';
import { PageEditorQuill } from '../../../../components';
import { CollectiveAdDTO } from '../../../../types';
import { CollectiveAdStatus } from '../../../../enums/collective-ad-statuses';


interface Props {
	id: number | string;
}


const EditCollectiveAdForm = ({ id }: Props): JSX.Element => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [values, setValues] = useState<CollectiveAdDTO>();
	const [note, setNote] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [paymentMethod, setPaymentMethod] = useState<string>('');
	const showLandingLink = values?.status === CollectiveAdStatus.STARTED;
	const allowManageConsultants = values?.status === CollectiveAdStatus.OPEN;


	const handleUpdate = () => {
		if (id) {
			const values = form.getFieldsValue();
      const collectiveAd = {
				...values,
				description: description,
				note: note,
				paymentMethod: paymentMethod,
        priceInCents: values.priceInCents * 100,
      }
      updateCollectiveAd(id, collectiveAd).then(() => {
        history.push('/collective-ads');
      });
    }
	}


	useEffect(() => {
    if (id) {
      getCollectiveAdById(id).then(data => {
        const displayData = {
					...data,
          startDate: moment(data.startDate),
					endDate: moment(data.endDate),
					mailingId: (data as any).mailing.id,
          priceInCents: data.priceInCents ? data.priceInCents / 100 : null,
        }
				setValues(data);
				form.setFieldsValue(displayData);
			});
    }
  }, [form.setFieldsValue, form, id]);
	
	const landingLink = `${process.env.REACT_APP_LANDING_URL}/${btoa((id).toString())}`;


	return (
		<>
			<h1>Редактирование коллективной рекламы</h1>
			{showLandingLink ? (
				<label htmlFor="a">{'Лендинг: '}<a href={landingLink}>{landingLink}</a></label>
			) : <p>Cсылка на лендинг будет доступна только после изменения статуса</p>}
			
			<CollectiveAdStatistics id={id} />
			<Divider style={{ marginTop: '30px' }}  orientation="left">Редактирование коллективной рекламы</Divider>
			<Form form={form}>
				<Form.Item
					label="Название"
					name="name"
					wrapperCol={{ span: 10 }}
					rules={[{ required: true, message: 'Название коллективной рекламы должно быть заполнено!' }]}
				>
					<Input placeholder="Название коллективной рекламы" autoFocus />
				</Form.Item>
				<Form.Item
					required
					rules={[{ required: true, message: 'Описание коллективной рекламы должно быть заполнено!' }]}
				>
					{(values?.description !== undefined || !id) &&
						<PageEditorQuill
							body={values?.description ?? description}
							onChange={(body) => setDescription(body)}
							label="Описание"
						/>
          }
				</Form.Item>
				<Form.Item>
					{ (values?.note !== undefined || !id)  &&
						<PageEditorQuill
							body={values?.note ?? note}
							onChange={(body) => setNote(body)}
							label="Заметка"
						/>
          }
				</Form.Item>
				<Form.Item
					required
					rules={[{ required: true, message: 'Способ оплаты коллективной рекламы должен быть заполнен!' }]}
				>
					{ (values?.paymentMethod !== undefined || !id)  &&
						<PageEditorQuill
							body={values?.paymentMethod ?? paymentMethod}
							onChange={(body) => setPaymentMethod(body)}
							label="Способ оплаты"
						/>
          }
				</Form.Item>
				<Form.Item
					label="Дата начала"
					name="startDate"
					rules={[{ required: true, message: 'Начало коллективной рекламы должна быть указана!' }]}
				>
					<DatePicker format={'DD/MM/YYYY'} />
				</Form.Item>
				<Form.Item
					label="Дата конца"
					name="endDate"
					rules={[{ required: true, message: 'Начало коллективной рекламы должна быть указана!' }]}
				>
					<DatePicker format={'DD/MM/YYYY'} />
				</Form.Item>
					<>
						<Form.Item
							label="Бюджет"
							name="priceInCents"
							rules={[{ required: true, message: 'Стоимость коллективной рекламы должна быть указана!' }]}
						>
							<InputNumber
								controls={false}
								placeholder="Бюджет"
								min={0}
							/>
						</Form.Item>
						<Form.Item
							label="Статус"
							name="status"
							rules={[{ required: true, message: 'Статус коллективной рекламы должен быть указан!' }]}
						>
						<Radio.Group>
								<Radio.Button value={CollectiveAdStatus.OPEN}>Открыта</Radio.Button>
								<Radio.Button value={CollectiveAdStatus.STARTED}>Запущена</Radio.Button>
								<Radio.Button value={CollectiveAdStatus.COMPLETED}>Завершена</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</>
				<Button onClick={handleUpdate} htmlType="submit">Обновить</Button>
			</Form>
			{allowManageConsultants &&
				<>
					<CollectiveAdConsultants collectiveAdId={Number(id)} />
				</>
			}
			<CollectiveAdLeads collectiveAdId={Number(id)} />
		</>
	);
}


export { EditCollectiveAdForm };
