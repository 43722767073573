import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Modal } from 'antd';

import { readAllPages, deletePage } from '../../../../api/pages.api';
import { DataList } from '../../../../components';
import { PagesListAllResponseDTO } from '../../../../types';

import './subpages-list.style.css';

export const SubpagesList = () => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
    const [pages, setPages] = useState<Array<PagesListAllResponseDTO>>([]);
	const { id: pageId }  = useParams<Record<string, string>>();
    const history = useHistory();


    const handleCreate = (): void => {
        history.push(`/pages/${pageId}/subpages/create`);
    }

    const handleDelete = (id: number): void => {
        deletePage(id).then(() =>
            setPages(pages?.filter(item => item.id !== id))
        );
    }

    const handleEdit = (id: number): void => {
        history.push(`/pages/${pageId}/subpages/${id}`);
    }

    const showModal = () => {
        setIsModalVisible(true);
      };
      
    const handleOk = () => {
        setIsModalVisible(false);
        if (selectedItemId) {
            handleDelete(selectedItemId);
        }
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const dataModel = [
        { title: 'ID', dataIndex: 'id' },
        { title: 'Ключ', dataIndex: 'name' },
        { title: 'Название', dataIndex: 'verboseName' }
    ];

    useEffect(() => {
        readAllPages(pageId)
            .then(pageResponse => {
                const dataSource = pageResponse
                    .map(item => ({ ...item, key: item.id }));
                setPages(dataSource);
            });
    }, [setPages, pageId]);

    return (
        <>
            <DataList
                title="Подстраницы"
                dataModel={dataModel}
                data={pages}
                onCreateButtonClick={() => handleCreate()}
                onDeleteButtonClick={(id) => {
                    showModal();
                    setSelectedItemId(id);
                }}
                onEditButtonClick={(id) => handleEdit(id)}
            />
            <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Вы действительно хотите удалить эту подстраницу?</p>
            </Modal>
        </>
    );
}
