import { MessageResponseDTO, UpsertMessageDTO } from './DTO';
import { APIFetch } from '../common/api-request';

async function readMessagesByMailingId (mailingId: number|string): Promise<Array<MessageResponseDTO>> {
  const response = await APIFetch({
    method: 'GET',
    path: 'messages/all',
    queryParams: { mailingId: mailingId.toString() },
    isAuthorized: true,
  });

  return response.json();
}

async function updateMessage (id: number | string, body: UpsertMessageDTO): Promise<MessageResponseDTO> {
  const response = await APIFetch({
    method: 'PUT',
    path: 'messages',
    queryParams: { id: id.toString() },
    payload: body,
    isAuthorized: true,
  });
  return response.json();
}

async function createMessage (body: UpsertMessageDTO): Promise<MessageResponseDTO> {
  const response = await APIFetch({
    method: 'POST',
    path: 'messages',
    payload: body,
    isAuthorized: true,
  });

  return response.json();
}

async function deleteMessage (id: number | string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'messages',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

export { readMessagesByMailingId, updateMessage, createMessage, deleteMessage };
