import { Switch, Route } from 'react-router-dom';

import { UpdatePageController, CreatePageController } from '../modules/pages/upsert-page/upsert-page.controller';
import { CreateLessonController, UpdateLessonController } from '../modules/courses/upsert-lesson/upsert-lesson.controller';
import { CreateCourseController, UpdateCourseController } from '../modules/courses/upsert-course/upsert-course.controller';
import { CreateMailingController, UpdateMailingController } from '../modules/mailings/upsert-mailing/upsert-mailing.controller';
import { CreateTariffController, UpdateTariffController } from '../modules/tariffs/upsert-tariff/upsert-tariff.controller';
import { CreateDiscountController, UpdateDiscountController } from '../modules/discounts/upsert-discount/upsert-discount.controller';
import { FilesList } from '../modules/files/files-list/files-list.component';
import { UploadFile } from '../components';

import { PrivateRoute } from './private-route.component'
import { PagesList } from '../modules/pages/pages-list/pages-list.component';
import { CommentsList } from '../modules/comments-list/comments-list.component';
import { CoursesList } from '../modules/courses/courses-list/courses-list.component';
import { MailingsList } from '../modules/mailings/mailings-list/mailings-list.component';
import { SendMessage } from '../modules/send-message/send-message.component';
import { LandingInfo } from '../modules/landing-info/landing-info.component';
import { Login } from '../modules/login/login.component';
import { TariffsList } from '../modules/tariffs/tariffs-list';
import { DiscountsList } from '../modules/discounts/discounts-list';
import { SubscriptionsList } from '../modules/subscriptions/subscriptions-list';
import { CollectiveAdsList } from '../modules/collective-ads/collective-ads-list';
import { CreateCollectiveAdsController, UpdateCollectiveAdsController } from '../modules/collective-ads/upsert-collective-ads/upsert-collective-ads.controller';
import { CreatePageTranslationsController, UpdatePageTranslationsController } from '../modules/pages/page-translations/upsert-page-translations/upsert-page-translations';
import { CreateSubpageController, UpdateSubpageController } from '../modules/pages/subpages/upsert-subpage/upsert-subpage.controller';
import { CreateSubpageTranslationsController, UpdateSubpageTranslationsController } from '../modules/pages/subpages/subpage-translations/upsert-subpage-translations/upsert-subpage-translations';

export const Router = () => {
    return (
        <Switch>
            <PrivateRoute path="/pages/:pageId/subpages/:subpageId/subpage-translations/create" children={<CreateSubpageTranslationsController />} />
            <PrivateRoute path="/pages/:pageId/subpages/:subpageId/subpage-translations/:id" children={<UpdateSubpageTranslationsController />} />
            <PrivateRoute path="/pages/:pageId/subpages/create" children={<CreateSubpageController />} />
            <PrivateRoute path="/pages/:pageId/subpages/:id" children={<UpdateSubpageController />} />
            <PrivateRoute path="/pages/:pageId/page-translations/create" children={<CreatePageTranslationsController />} />
            <PrivateRoute path="/pages/:pageId/page-translations/:id" children={<UpdatePageTranslationsController />} />
            <PrivateRoute path="/pages/create" children={<CreatePageController />} />
            <PrivateRoute path="/pages/:id" children={<UpdatePageController />} />
            <PrivateRoute path="/pages" children={<PagesList />} />
            <PrivateRoute path="/lessons/create" children={<CreateLessonController />} />
            <PrivateRoute path="/lessons/:id" children={<UpdateLessonController />} />
            <PrivateRoute path="/comments" children={<CommentsList />} />
            <PrivateRoute path="/courses/create" children={<CreateCourseController />} />
            <PrivateRoute path="/courses/:id" children={<UpdateCourseController />} />
            <PrivateRoute path="/courses" children={<CoursesList />} />
            <PrivateRoute path="/mailings/create" children={<CreateMailingController />} />
            <PrivateRoute path="/mailings/:id" children={<UpdateMailingController />} />
            <PrivateRoute path="/mailings" children={<MailingsList />} />
            <PrivateRoute path="/files/create" children={<UploadFile />} />
            <PrivateRoute path="/files" children={<FilesList />} />
            <PrivateRoute path="/instant-messages" children={<SendMessage />} />
            <PrivateRoute path="/landing/:id" children={<LandingInfo />} />
            <PrivateRoute path="/tariffs/create" children={<CreateTariffController />} />
            <PrivateRoute path="/tariffs/:id" children={<UpdateTariffController />} />
            <PrivateRoute path="/tariffs" children={<TariffsList />} />
            <PrivateRoute path="/collective-ads/create" children={<CreateCollectiveAdsController />} />
            <PrivateRoute path="/collective-ads/:id" children={<UpdateCollectiveAdsController />} />
            <PrivateRoute path="/collective-ads" children={<CollectiveAdsList />} />
            <PrivateRoute path="/discounts/create" children={<CreateDiscountController />} />
            <PrivateRoute path="/discounts/:id" children={<UpdateDiscountController />} />
            <PrivateRoute path="/discounts" children={<DiscountsList />} />
            <PrivateRoute path="/subscriptions" children={<SubscriptionsList />} />
            <Route path="/login" children={<Login />} />
        </Switch>
    );
}
