import { useState, useEffect } from 'react';
import { Comment, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CommentResponseDTO } from '../../api/DTO';
import { getAllComments } from './comments-list.requests';


export const CommentsList = () => {
    const [ comments, setComments ] = useState<Array<CommentResponseDTO>>([]);
    useEffect(() => {
        getAllComments().then(setComments);
    }, [ setComments ]);

    return (
        <>
            <h2>Комментарии</h2>
            {
                comments.map((item, i) =>
                    <Comment
                        key={i}
                        author={item.name}
                        content={
                            <>
                                <p>УРОК {item.lessonId}</p>
                                <p>{item.comment}</p>
                            </>
                        }
                        avatar={<Avatar shape="square" size={64} icon={<UserOutlined />} />}
                    />
                )
            }
        </>
    )
}
