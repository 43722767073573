import { SubscriptionDTO } from './DTO';

import { APIFetch } from '../common/api-request';


async function getAllSubscriptions (): Promise<Array<SubscriptionDTO>> {
  const subscriptions = await APIFetch({
    method: 'GET',
    path: 'subscriptions/all',
    isAuthorized: true,
  });
  return subscriptions.json();
}


export {
  getAllSubscriptions,
};
