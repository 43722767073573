import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { readAllLessons, deleteLesson } from '../../../api/lessons.api';
import { LessonResponseDTO } from '../../../api/DTO';
import { DataList } from '../../../components';

interface LessonsListProps {
    courseId: number | string;
}
  

export const LessonsList = ({ courseId }: LessonsListProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
    const [lessons, setLessons] = useState<Array<LessonResponseDTO>>([]);
    const history = useHistory();

    useEffect(() => {
        readAllLessons(courseId).then(setLessons);
    }, [ setLessons, courseId ]);

    const handleCreate = (): void => {
        history.push(`/lessons/create?courseId=${courseId}`);
    }

    const handleDelete = (id: number): void => {
        deleteLesson(id).then(() => {
            setLessons(lessons.filter(item => item.id !== id));
        });
    }

    const handleUpdate = (id: number): void => {
        history.push(`/lessons/${id}`);
    }

    const showModal = () => {
        setIsModalVisible(true);
      };
      
    const handleOk = () => {
        setIsModalVisible(false);
        if (selectedItemId) {
            handleDelete(selectedItemId);
        }
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const dataModel = [
        { title: 'ID', dataIndex: 'id' },
        { title: 'Порядок урока', dataIndex: 'lessonOrder' },
        { title: 'Название', dataIndex: 'title'}
    ];

    return (
        <>
            <DataList
                title="Уроки"
                dataModel={dataModel}
                data={lessons}
                onCreateButtonClick={() => handleCreate()}
                onDeleteButtonClick={(id) => {
                    showModal();
                    setSelectedItemId(id);
                }}
                onEditButtonClick={(id) => handleUpdate(id)}
            />
            <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Вы действительно хотите удалить этот урок?</p>
            </Modal>
        </>
    );
}
