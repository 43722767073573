import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, Input, Switch } from 'antd';

import { readPageById, createPage, updatePage } from '../../../../api/pages.api';
import { Page } from '../../../../types';

interface UpsertPageProps {
    subpageId?: string;
}

export const UpsertSubpage = ({ subpageId }: UpsertPageProps) => {
    const history = useHistory();
    const [page, setPage] = useState<Page>();
	const { pageId }  = useParams<Record<string, string>>();
    const [form] = Form.useForm();


    useEffect(() => {
        if (subpageId) {
            readPageById(subpageId).then(body => {
                setPage(body);
                form.setFieldsValue(body);
            });
        }
    }, [setPage, subpageId, form]);

    const handleUpdatePageClick = (): void => {
        if (page) {
            updatePage(page.id.toString(), form.getFieldsValue()).then();
            history.push(`/pages/${pageId}`);
        }
    }

    const handleCreatePageClick = (): void => {
        const values = form.getFieldsValue();
        createPage({ ...values, parentId: pageId }).then();
        history.push(`/pages/${pageId}`);
    }

    return (
        <>
        { subpageId ? <h1>Редактирование подстраницы</h1> : <h1>Создание подстраницы</h1> }
            <Form form={form}>
                <Form.Item label="Ключ" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Имя" name="verboseName">
                    <Input />
                </Form.Item>
                <Form.Item label="Общедоступная" name="isFree" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label="Видимость" name="isPublic" valuePropName="checked">
                    <Switch />
                </Form.Item>
                { subpageId ?
                    <Button onClick={handleUpdatePageClick}>
                        Обновить
                    </Button> :
                    <Button onClick={handleCreatePageClick}>
                        Создать
                    </Button>
                }
            </Form>
        </>
    )
}
