import { useEffect, useState } from 'react';
import { Button, Collapse, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { MessageResponseDTO } from '../../../api/DTO';
import { createMessage, deleteMessage, readMessagesByMailingId } from '../../../api/messages.api';
import { MessageGroup } from '../message-group/message-group.component';

type MessagesListProps = {
  mailingId: number;
  courseId: number;
}

export const MessagesList = ({ mailingId, courseId }: MessagesListProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [messages, setMessages] = useState<Array<MessageResponseDTO>>([])
  
  useEffect(() => {
    readMessagesByMailingId(mailingId).then(setMessages);
  }, [setMessages, mailingId]);

  const handleAddMessageGroup = () => {
    createMessage({
      mailingId: mailingId,
      telegramMessage: [],
      delaySeconds: 0,
      lessonId: null,
      fromDayStart: false,
    }).then(createdMessage =>
      setMessages([ ...messages, createdMessage ]));
  }

  const handleDeleteMessageGroup = (id: number) => {
    setMessages(messages.filter(item => item.id !== id));
    deleteMessage(id).then();
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDeleteMessageGroup(selectedItemId);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Collapse accordion={true}>
        {messages?.map(message =>
            <Collapse.Panel key={message.id} header="Группа сообщений" extra={[
              <Button
                type="text"
                key={`btn${message.id}`}
                icon={<DeleteOutlined /> }
                onClick={() => {
                  showModal();
                  setSelectedItemId(message.id);
                }}
              />
            ]}>
            <MessageGroup message={message} courseId={courseId} />
          </Collapse.Panel>)}
      </Collapse>
      <Button onClick={() => handleAddMessageGroup()}>Добавить группу сообщений</Button>
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить эту группу сообщений?</p>
      </Modal>
    </>
  );
}
