import { TariffDTO, CreateTariffDTO, TariffMinPriceDTO } from './DTO';

import { APIFetch } from '../common/api-request';


async function getAllTariffs (): Promise<Array<TariffDTO>> {
  const tariffs = await APIFetch({
    method: 'GET',
    path: 'tariff/all',
    isAuthorized: true,
  });
  return tariffs.json();
}

async function getTariffById (id: number | string): Promise<TariffDTO> {
  const response = await APIFetch({
    method: 'GET',
    path: 'tariff',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
  return response.json();
}

async function createTariff (body: CreateTariffDTO): Promise<void> {
  await APIFetch({
    method: 'POST',
    path: 'tariff',
    payload: body,
    isAuthorized: true,
  });
}

async function deleteTariff (id: number | string): Promise<void> {
  await APIFetch({
    method: 'DELETE',
    path: 'tariff',
    queryParams: { id: id.toString() },
    isAuthorized: true,
  });
}

async function getAllArchivedTariffs (): Promise<void> {
  const archivedTariffs = await APIFetch({
    method: 'GET',
    path: 'tariff/archived',
    isAuthorized: true,
	});
	return archivedTariffs.json();
}

async function getTariffMinPrice (duration: number): Promise<TariffMinPriceDTO> {
  const response = await APIFetch({
    method: 'GET',
    path: 'tariff/min-price',
    queryParams: { duration: duration.toString() },
    isAuthorized: true,
  });
  return response.json();
}


export {
  getAllTariffs,
  getTariffById,
  createTariff,
	deleteTariff,
  getAllArchivedTariffs,
  getTariffMinPrice,
};
