import { SystemTimeDTO } from './DTO';

import { APIFetch } from '../common/api-request';

async function getSystemTime (): Promise<SystemTimeDTO> {
  const response = await APIFetch({
    method: 'GET',
    path: 'system/time',
    isAuthorized: true,
  });

  return response.json();
}

export {
  getSystemTime,
};
