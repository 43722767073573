import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { readAllCourses, deleteCourse } from '../../../api/courses.api';
import { DataList } from '../../../components';
import { CourseResponseDTO } from '../../../types';


export const CoursesList = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [courses, setCourses] = useState<Array<CourseResponseDTO>>([]);
  const history = useHistory();

  useEffect(() => {
    readAllCourses()
      .then(setCourses);
  }, []);

  const handleCreate = (): void => {
    history.push('/courses/create');
  }

  const handleEdit = (id: number): void => {
    history.push(`/courses/${id}`);
  }

  const handleDelete = (id: number): void => {
    deleteCourse(id)
      .then(() => {
        setCourses(courses.filter(course => course.id !== id));
      });
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDelete(selectedItemId);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dataModel = [
    { title: 'ID', dataIndex: 'id' },
    { title: 'Название', dataIndex: 'title' },
    { title: 'Описание', dataIndex: 'description' }
  ];

  return (
    <>
      <DataList
        title="Курсы"
        dataModel={dataModel}
        data={courses}
        onCreateButtonClick={() => handleCreate()}
        onDeleteButtonClick={(id) => {
          showModal();
          setSelectedItemId(id);
        }}
        onEditButtonClick={(id) => handleEdit(id)}
      />
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить этот курс?</p>
      </Modal>
    </>
  );
}
