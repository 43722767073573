import { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Radio } from 'antd';
import { MessageTypeEnum } from '../../../enums/message-type';
import { TgMessageDTO } from '../../../api/DTO';
import { WysiwygInput } from '../../../components';

interface MessageProps {
  tgMessage?: TgMessageDTO;
  onChange: (tgMessage: Partial<TgMessageDTO>) => void;
}

export const Message = ({ tgMessage, onChange }: MessageProps) => {
  const [form] = Form.useForm();
  const [messageType, setMessageType] = useState<MessageTypeEnum>();
  const [tgHTMLMessage, setTgHTMLmessage] = useState<string>('');
  
  const initialValues: TgMessageDTO = {
    type: MessageTypeEnum.TEXT,
    payload: undefined,
    signaturePayload: {
      registrationInCompany: "Регистрация в компанию: {{registrationToCompanyLink}}",
      contact: "Для связи: {{contact}}",
      consultantChannel: "Авторский канал: {{consultantChannelLink}}",
      systemActivation: "Активация системы: {{systemActivation}}",
    },
    imageText: undefined,
    buttonText: undefined,
    buttonUrl: undefined,
    fileId: undefined,
  }

  useEffect(() => {
    if (tgMessage) {
      form.setFieldsValue(tgMessage);
      setMessageType(tgMessage.type);
      setTgHTMLmessage(tgMessage.payload ?? '');
    }
  }, [form, tgMessage]);

  const handleMessageTypeChange = (messageType: MessageTypeEnum) => {
    setMessageType(messageType);
    let newObj = {
      ...initialValues,
      type: messageType,
    };
    if (messageType === MessageTypeEnum.TEXT) newObj = { ...newObj, payload: prepareMessageForTG(tgHTMLMessage) };
    if (messageType === MessageTypeEnum.BUTTON) newObj = { ...newObj, payload: prepareMessageForTG(tgHTMLMessage) };
    if (messageType !== MessageTypeEnum.SIGNATURE) newObj = { ...newObj, signaturePayload: undefined };
    onChange(newObj as Partial<TgMessageDTO>);
  }

  const removeNonAllowedTags = (input: string) => {
    const removedP = input.replace(/<p>/g, '\n');
    const allowedTags = ['em', 'i', 'b', 'strong', 'a', 'code', 'pre', 'strike', 's', 'ins', 'u', 'del'];
    const htmlTagPattern = /<\s*\/?\s*(\w+)[^>]*>/g;
  
    const sanitizedString = removedP.replace(htmlTagPattern, (match, p1) => {
      let isAllowed = false;
  
      allowedTags.forEach(allowedTag => {
        if (p1.toLowerCase() === allowedTag) {
          isAllowed = true;
        }
      });
  
      return isAllowed ? match : '';
    });

    return sanitizedString;
  }

  const wrapEmojisWithTGTag = (input: string): string => {
    const emojiPattern = /([\uD800-\uDBFF][\uDC00-\uDFFF])/g;
    return input.replace(emojiPattern, '<tg-emoji emoji-id="5368324170671202286">$1</tg-emoji>');
  };

  const removeAutoBreakWrapper = (html: string): string => html.replace(/<p><br><\/p>/g, '\n');
  const changeLineBreaksForWysiwyg = (html: string): string => html.replace(/\n/g, '<br>');
  const changeLineBreaksForTGmessage = (html: string): string => html.replace(/<br>/g, '\n');
  // const removeNonBreakingSpaces = (html: string): string => html.replace(/&nbsp;/g, ' ');

  const prepareMessageForTG = (input: string): string => {
    const removedWrappedBreaks = removeAutoBreakWrapper(input);
    const changedLineBreaks = changeLineBreaksForTGmessage(removedWrappedBreaks);
    const removedNonAllowedTags = removeNonAllowedTags(changedLineBreaks);
    const wrappedEmojis = wrapEmojisWithTGTag(removedNonAllowedTags);
    return wrappedEmojis;
  }

  const prepareMessageForWysiwyg = (input: string): string => {
    const removedAutoBreakWrapper = removeAutoBreakWrapper(input);
    const removedFirstBreak = removedAutoBreakWrapper.replace(/\n/, '');
    const changedLineBreaks = changeLineBreaksForWysiwyg(removedFirstBreak);
    return changedLineBreaks;
  }
  
  const handleTextValueChange = (e: string) => {
    if (!e) return;
    const payload = prepareMessageForTG(e);
    setTgHTMLmessage(payload);
    form.setFields([{ name: 'payload', value: payload }])
    const newObj = { payload };
    onChange(newObj as Partial<TgMessageDTO>);
  }


  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={(e, b) => onChange(b)}
      >
        <Form.Item label="Тип сообщения" name="type">
          <Radio.Group value={messageType} onChange={(e) => handleMessageTypeChange(e.target.value)}>
            <Radio.Button value={MessageTypeEnum.TEXT}>Текст</Radio.Button>
            <Radio.Button value={MessageTypeEnum.PHOTO}>Картинка</Radio.Button>
            <Radio.Button value={MessageTypeEnum.BUTTON}>Кнопка</Radio.Button>
            <Radio.Button value={MessageTypeEnum.VOICE}>Голосовое сообщение</Radio.Button>
            <Radio.Button value={MessageTypeEnum.VIDEO_NOTE}>Кружочек</Radio.Button>
            <Radio.Button value={MessageTypeEnum.SIGNATURE}>Подпись</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {messageType === MessageTypeEnum.TEXT &&
          <WysiwygInput
            defaultValue={prepareMessageForWysiwyg(tgHTMLMessage)}
            setData={handleTextValueChange}
            label="Текст сообщения"
          />
        }
        { messageType === MessageTypeEnum.PHOTO &&
          <Form.Item label="Ссылка на картинку" name="payload">
            <Input />
          </Form.Item>
        }
        { messageType === MessageTypeEnum.BUTTON &&
          <>
            <div style={{ marginBottom: '24px'}}>
              <WysiwygInput
                defaultValue={prepareMessageForWysiwyg(tgHTMLMessage)}
                setData={handleTextValueChange}
                label="Текст сообщения"
              />
            </div>
            <Form.Item label="Надпись на кнопке" name="buttonText">
              <Input />
            </Form.Item>
            <Form.Item label="Ссылка кнопки" name="buttonUrl">
              <Input />
            </Form.Item>
          </>
        }
        { (messageType === MessageTypeEnum.VOICE || messageType === MessageTypeEnum.VIDEO_NOTE) &&
          <>
            <Form.Item label="ID файла" name="fileId">
              <InputNumber />
            </Form.Item>
          </>
        }
        {messageType === MessageTypeEnum.SIGNATURE && (
          <>
            <Form.Item label="Регистрация в компанию" name={["signaturePayload", "registrationInCompany"]}>
              <Input />
            </Form.Item>
            <Form.Item label="Активация системы" name={["signaturePayload", "systemActivation"]}>
              <Input />
            </Form.Item>
            <Form.Item label="Для связи" name={["signaturePayload", "contact"]}>
              <Input />
            </Form.Item>
            <Form.Item label="Авторский канал" name={["signaturePayload", "consultantChannel"]}>
              <Input />
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
}
