import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { DiscountDTO } from '../../../api/DTO';
import { DataList } from '../../../components';
import { getAllDiscounts } from '../../../api/discounts.api';


export const DiscountsList = () => {
  const [tariffs, setTariffs] = useState<Array<DiscountDTO>>([]);
  const history = useHistory();

  useEffect(() => {
    getAllDiscounts().then(discounts => {
      const mappedDiscounts = discounts.map((discount) => {
        return {
          ...discount,
          appliedAt: discount.appliedAt ? dayjs(discount.appliedAt).format('DD/MM/YYYY') : null,
        }
      });
      setTariffs(mappedDiscounts);
    });
  }, [ setTariffs ]);

  const handleCreate = (): void => {
    history.push('/discounts/create')
  }

  const handleEdit = (id: number | string): void => {
    history.push(`/discounts/${id}`);
  }

  const handleCopyToClipboard = (code: string): void => {
    const { REACT_APP_CABINET_BASE_URL, REACT_APP_CABINET_DISCOUNT_PATH } = process.env;
    const discountLink = `${REACT_APP_CABINET_BASE_URL}${REACT_APP_CABINET_DISCOUNT_PATH}${code}`;
    navigator.clipboard.writeText(discountLink);
    copyToClipboardNotification();
  }

  const copyToClipboardNotification = () => {
    notification.success({
      message: 'Промокод скопирован!',
      description: 'Промокод скопирован в буфер обмена. Теперь вы можете вставить его оттуда куда угодно!'
    });
  };


  const dataModel = [
    { title: 'ID', dataIndex: 'id' },
    { title: 'ID Тарифа', dataIndex: 'tariffId' },
    { title: 'Скидка, %', dataIndex: 'discountPercent' },
    { title: 'Промокод', dataIndex: 'code' },
    { title: 'Дата использования', dataIndex: 'appliedAt' },
  ];
  
  return (
    <>
      <DataList
        title="Промокоды"
        dataModel={dataModel}
        data={tariffs}
        onCreateButtonClick={() => handleCreate()}
        onEditButtonClick={(id) => handleEdit(id)}
        onCopyButtonClick={(record) => handleCopyToClipboard(record.code)}
        itemsDeletable={false}
        itemsEditable={false}
      />
    </>
  );
}
