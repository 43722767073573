import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';

import { DataList } from '../../../components';
import { TariffDTO } from '../../../api/DTO';
import { getAllTariffs, deleteTariff } from '../../../api/tariffs.api';

export const TariffsList = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [tariffs, setTariffs] = useState<Array<TariffDTO>>([]);
  const history = useHistory();

  useEffect(() => {
    getAllTariffs().then(tariffs => {
      const mappedTariffs = tariffs.map((tariff) => {
        return {
          ...tariff,
          deletedAt: tariff.deletedAt ? dayjs(tariff.deletedAt).format('DD/MM/YYYY') : null,
          priceInCents: tariff.priceInCents / 100,
        }
      });
      setTariffs(mappedTariffs);
    });
  }, [ setTariffs ]);

  const handleCreate = (): void => {
    history.push('/tariffs/create')
  }

  const handleEdit = (id: number | string): void => {
    history.push(`/tariffs/${id}`);
  }

  const handleDelete = (id: number | string): void => {
    deleteTariff(id);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDelete(selectedItemId);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dataModel = [
    { title: 'ID', dataIndex: 'id' },
    { title: 'Название', dataIndex: 'title' },
    { title: 'Длительность', dataIndex: 'durationDays' },
    { title: 'Цена, $', dataIndex: 'priceInCents'},
    { title: 'Дата архивации', dataIndex: 'deletedAt'},
  ];
  
  return (
    <>
      <DataList
        title="Тарифы"
        dataModel={dataModel}
        data={tariffs}
        onCreateButtonClick={() => handleCreate()}
        onDeleteButtonClick={(id) => {
          showModal();
          setSelectedItemId(id);
        }}
        onEditButtonClick={(id) => handleEdit(id)}
      />
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить этот тариф?</p>
      </Modal>
    </>
  );
}
