import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';

import { DataList } from '../../../../components';
import { PageTranslation } from '../../../../types';
import { deletePageTranslations } from '../../../../api/pages.api';
import './page-translations-list.css';


type PageTranslationsListProps = {
  pageId: string;
  translations: Array<PageTranslation>;
}

export const PageTranslationsList = ({ pageId, translations }: PageTranslationsListProps) => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [pageTranslations, setPageTranslations] = useState<Array<PageTranslation>>([]);

  const handleCreate = (): void => {
    history.push(`${pageId}/page-translations/create`);
  }

  const handleDelete = (id: number): void => {
    deletePageTranslations(id).then(() =>
      setPageTranslations(pageTranslations?.filter(item => item.id !== id))
    );
  }

  const handleEdit = (id: number): void => {
    history.push(`${pageId}/page-translations/${id}`);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDelete(selectedItemId);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dataModel = [
      { title: 'ID', dataIndex: 'id' },
      { title: 'Язык', dataIndex: 'language' },
      { title: 'Название', dataIndex: 'name' }
  ];

  useEffect(() => {
    setPageTranslations(translations);
  }, [translations, setPageTranslations]);

  return (
    <>
      <DataList
        title="Переводы страницы"
        dataModel={dataModel}
        data={pageTranslations}
        onCreateButtonClick={() => handleCreate()}
        onDeleteButtonClick={(id) => {
          showModal();
          setSelectedItemId(id);
        }}
        onEditButtonClick={(id) => handleEdit(id)}
      />
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить этот перевод?</p>
      </Modal>
    </>
  );
}
