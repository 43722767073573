import { useEffect, useState}  from 'react';
import { Input, InputNumber } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
dayjs.extend(duration);
dayjs.extend(objectSupport)

type Unit = 'seconds' | 'minutes' | 'hours' | 'days';

export interface Props {
  value?: number;
  onChange?: (value: number) => void;
  showDays: boolean;
}

export const TimePicker = ({ value, onChange, showDays, }: Props) => {
  const [time, setTime] = useState<Record<Unit, number>>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const seconds = value ?? 0;
    const duration = dayjs.duration(seconds, 'seconds');

    setTime({
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    })
  }, [value]);

  const handleChange = (unit: Unit, value: number) => {
    setTime({ ...time, [unit]: value, });
    const totalDuration = dayjs.duration({ ...time, [unit]: value, });
    const totalSeconds = totalDuration.asSeconds();
    if (onChange) onChange(totalSeconds);
  }

  return (
    <>
      <Input.Group compact>
        { showDays &&
          <InputNumber style={{width: 100}} addonBefore="дн" min={0} value={time.days} onChange={(value) => handleChange('days', value)} />
        }
        <InputNumber style={{width: 100}} addonBefore="час" min={0} value={time.hours} max={23} onChange={(value) => handleChange('hours', value)} />
        <InputNumber style={{width: 100}} addonBefore="мин" min={0} value={time.minutes} max={59} onChange={(value) => handleChange('minutes', value)} />
        <InputNumber style={{width: 100}} addonBefore="сек" min={0} value={time.seconds} max={59} onChange={(value) => handleChange('seconds', value)} />
      </Input.Group>
    </>
  );
}
