import { useEffect, useState } from 'react';
import { Button, Collapse, Form, Modal, Select, Switch } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { TimePicker } from '../../components';
import { readAllMailings, sendInstantMessage } from '../../api/mailings.api';
import { Message } from '../mailings/message/message.component';
import { TgMessageDTO } from '../../api/DTO';
import { MessageTypeEnum } from '../../enums/message-type';

export const SendMessage = () => {
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [mailingsList, setMailingsList] = useState<Array<any> | undefined>();
  const [form] = Form.useForm();
  const [messages, setMessages] = useState<Array<TgMessageDTO>>([])

  useEffect(() => {
    readAllMailings().then((mailings) => {
      setMailingsList(mailings);
    });
  }, []);

  const handleSubmit = (): void => {
    const formData = form.getFieldsValue();
    const requestBody = {
      mailingId: formData.mailingId,
      delaySeconds: formData.delaySeconds,
      sendOnlyOnCompletedMailing: formData.sendOnlyOnCompletedMailing,
      telegramMessage: messages,
    }
    console.log(requestBody)
    sendInstantMessage(requestBody).then();
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleSubmit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeMessage = (tgMessage: Partial<TgMessageDTO>, i: number) => {
    const newMessage = [ ...messages, ];
    newMessage[i] = { ...newMessage[i], ...tgMessage, }
    setMessages(newMessage);
  }

  const handleDeleteTgMessage = (i: number) => {
    const newMessages = [ ...messages, ];
    newMessages.splice(i, 1);

    setMessages(newMessages);
  }

  const handleAddMessage = () => {
    const newTgMessage = {
      type: MessageTypeEnum.TEXT,
      payload: undefined
    };
    const newMessage = [
      ...messages, newTgMessage,
    ];
    setMessages(newMessage);
  }

  return (
    <>
      <h1>Отправка сообщений</h1>
      <Form form={form}>
        <Form.Item label="Рассылки" name="mailingId">
          <Select placeholder="Рассылки" style={{ width: 500 }}>
            {mailingsList && mailingsList.map(({ id, title }: any) =>
              (<Option key={id} value={id}>{title}</Option>)
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Задержка:" name="delaySeconds">
          <TimePicker showDays={true} />
        </Form.Item>
        <Form.Item label="Отправить только если нет сообщений в очереди" name="sendOnlyOnCompletedMailing" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Collapse>
          {messages.map((tgMessage, i) =>
            <Collapse.Panel key={i} header="Сообщение" extra={[
              <Button
                key={`btn${i}`}
                icon={<DeleteOutlined />}
                type="text"
                onClick={() => {
                  handleDeleteTgMessage(i);
                }} />
            ]}>
              <Message tgMessage={tgMessage} onChange={(tgMessage) => handleChangeMessage(tgMessage, i)} />
            </Collapse.Panel>
          )}
        </Collapse>
        <Button onClick={() => handleAddMessage()}>Добавить сообщение</Button>
        <Button onClick={() => showModal()}>Отправить</Button>
        <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <p>Вы уверены что хотите отправить это сообщение?</p>
        </Modal>
      </Form>
    </>
  );
}
