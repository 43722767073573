import dayjs from 'dayjs';
import { Card, Empty } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { SubscriptionDTO, SubscriptionItem } from '../../../api/DTO';
import { getAllSubscriptions } from '../../../api/subscriptions.api';
import './subscriptions-list.component.css';

export const SubscriptionsList = () => {
  const [subscriptions, setSubscriptions] = useState<Array<SubscriptionItem>>([]);
  const baseWalletUrl = 'https://bscscan.com/address/';

  enum PaymentStatusEnum {
    WAITING = 'waiting',
    SUCCESSFUL = 'successful',
    CANCELED = 'canceled',
  }

  const mapOrderStatus = useCallback((status: string): string => {
    switch (status) {
      case PaymentStatusEnum.WAITING:
        return 'Ожидается';
      case PaymentStatusEnum.SUCCESSFUL:
        return 'Успешный';
      case PaymentStatusEnum.CANCELED:
        return 'Отменен';

      default:
        return 'Неизвестный статус';;
    }
  }, [PaymentStatusEnum])

  const mapMoneyValue = (value: number | null): string => value ? '$' + (value / 100).toFixed().toString() : '-';
  const mapDateValue = (date: string | null): string => date ? dayjs(date).format('DD/MM/YYYY, HH:mm:ss') : '-';

  useEffect(() => {
    getAllSubscriptions().then((subscriptions: SubscriptionDTO[]) => {
      const mappedSubscriptions = subscriptions.map((subscription) => {
        return {
          ...subscription,
          createdAt: mapDateValue(subscription.createdAt),
          startDate: mapDateValue(subscription.startDate),
          endDate: mapDateValue(subscription.endDate),
          initialPriceInCents: mapMoneyValue(subscription.initialPriceInCents),
          priceWithDiscountInCents: mapMoneyValue(subscription.priceWithDiscountInCents),
          commissionOwnerInCents: mapMoneyValue(subscription.commissionOwnerInCents),
          commissionReferralInCents: mapMoneyValue(subscription.commissionReferralInCents),
          commissionCommunityInCents: mapMoneyValue(subscription.commissionCommunityInCents),
          walletAddress: subscription.walletAddress,
          linkToTransactions: baseWalletUrl + subscription.walletAddress,
          status: mapOrderStatus(subscription.status),
        }
      });
      setSubscriptions(mappedSubscriptions);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSubscriptions]);



  const SubscriptionCard = (subscription: SubscriptionItem): JSX.Element => {
    const { id, createdAt, startDate, endDate,
      initialPriceInCents, priceWithDiscountInCents, commissionOwnerInCents,
      commissionReferralInCents, commissionCommunityInCents,
      orderId, walletAddress, linkToTransactions, status } = subscription;
    
    return (
      <div className="subscription-card" key={id}>
        <Card
          size="small"
          title={`Транзакция #${id}`}
          style={{ width: '100%' }}
          hoverable
          type="inner"
          className="transaction-card"
        >
          <p>Создан: {createdAt}</p>
          <p>Дата старта: {startDate}</p>
          <p>Дата завершения: {endDate}</p>
          <p>Начальная цена: {initialPriceInCents}</p>
          <p>Цена со скидкой: {priceWithDiscountInCents}</p>
          <p>Комиссия владельца: {commissionOwnerInCents}</p>
          <p>Комиссия реферала: {commissionReferralInCents}</p>
          <p>Комиссия общества: {commissionCommunityInCents}</p>
          <p>Order ID: {orderId}</p>
          <p>Номер кошелька: <a href={linkToTransactions}>{walletAddress}</a></p>
          <p>Статус заказа: {status}</p>
        </Card>
      </div>
    );
  }
  
  return (
    <>
      {subscriptions ? subscriptions.map((subscription) => SubscriptionCard(subscription)) : <Empty />}
    </>
  );
}
