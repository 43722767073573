import { FileResponseDTO } from './DTO';

import { APIFetch } from '../common/api-request';

async function readAllFiles (): Promise<Array<FileResponseDTO>> {
  const response = await APIFetch({
    method: 'GET',
    path: 'files/all',
    isAuthorized: true,
  });
  return response.json();
}

export { readAllFiles };
