import { FetchParams } from './interfaces/fetch-params.interface';

export const APIFetch = async (params: FetchParams): Promise<Response> => {
    const url = new URL(`${process.env.REACT_APP_BOT_URL}/${params.path}`);

    if (params.queryParams) {
        for (const item in params.queryParams) {
            if (params.queryParams.hasOwnProperty(item)) {
                url.searchParams.append(item, params.queryParams[item]);
            }
        }
    }

     const init: RequestInit = {
        method: params.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params.payload ?? undefined)
    };

    if (params.isAuthorized) {
       Object.assign(init.headers as HeadersInit, {Authorization: `Bearer ${localStorage.getItem('adminToken')}`});
    }

    return fetch(url.toString(), init);
}
