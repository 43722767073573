import { useParams } from 'react-router-dom';
import { BaseQueryDTO } from '../../../api/DTO';
import { UpsertCourse } from './upsert-course.component';


const CreateCourseController = () => <UpsertCourse />;

const UpdateCourseController = () => {
  const { id } = useParams<BaseQueryDTO>();
  return (<UpsertCourse id={id} />);
}

export { CreateCourseController, UpdateCourseController }
