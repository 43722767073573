import { LoginDTO, TokenDTO } from './DTO';
import { APIFetch } from '../common/api-request';

async function login (body: LoginDTO): Promise<TokenDTO> {
  const response = await APIFetch({
    method: 'POST',
    path: 'auth/login/admin',
    payload: body,
    isAuthorized: false,
  });
  if (response.status !== 200) {
    throw new Error('Invalid credentials');
  }
  return response.json();
}

export { login };
