import { useParams } from 'react-router-dom';
import { BaseQueryDTO } from '../../../api/DTO';
import { UpsertDiscount } from './upsert-discount.component';


const UpdateDiscountController = () => {
  const { id } = useParams<BaseQueryDTO>();
  return <UpsertDiscount id={ id }/>
}

const CreateDiscountController = () => {
  return <UpsertDiscount />
}

export { UpdateDiscountController, CreateDiscountController };
