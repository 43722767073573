import { Redirect, Route, RouteProps } from 'react-router-dom';


export const PrivateRoute = (props: RouteProps) => {
  const {children, ...rest} = props;
  const isAuthorized = !!localStorage.getItem('adminToken');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: location }}}
          />
        )
      }
    />
  );
}
