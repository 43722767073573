import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { DataList } from '../../../components';
import { deleteMailing, readAllMailings } from '../../../api/mailings.api';

interface MailingsDataModel {
  id: number;
  title: string;
  courseTitle: string;
  isPublic: string;
}


export const MailingsList = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [mailings, setMailings] = useState<Array<MailingsDataModel>>([]);
  const history = useHistory();

  useEffect(() => {
    readAllMailings().then(mailings => {
      const mappedMailings = mailings.map(({ id , title, course , isPublic }) => {
        const isMailingPublic = isPublic === true ? 'Публичная' : 'Закрытая';

        return {
          id: id,
          title: title,
          courseTitle: course.title,
          isPublic: isMailingPublic,
        }
      });
      setMailings(mappedMailings);
    });
  }, [ setMailings ]);

  const handleCreate = (): void => {
    history.push('/mailings/create')
  }

  const handleEdit = (id: number | string): void => {
    history.push(`/mailings/${id}`);
  }

  const handleDelete = (id: number | string): void => {
    deleteMailing(id).then(() =>
      setMailings(mailings.filter(item => item.id !== id))
    );
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedItemId) {
      handleDelete(selectedItemId);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dataModel = [
    { title: 'ID', dataIndex: 'id' },
    { title: 'Название', dataIndex: 'title' },
    { title: 'Курс', dataIndex: 'courseTitle' },
    { title: 'Видимость', dataIndex: 'isPublic' },
  ];
  
  return (
    <>
      <DataList
        title="Рассылки"
        dataModel={dataModel}
        data={mailings}
        onCreateButtonClick={() => handleCreate()}
        onDeleteButtonClick={(id) => {
          showModal();
          setSelectedItemId(id);
        }}
        onEditButtonClick={(id) => handleEdit(id)}
      />
      <Modal title="Подтвердите действие" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Вы действительно хотите удалить эту рассылку?</p>
      </Modal>
    </>
  );
}
