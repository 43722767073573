import { useEffect, useState } from 'react';
import { Alert, Button, Form, InputNumber, Select } from 'antd';
import { useHistory } from 'react-router-dom';

import { getDiscountById, createDiscount } from '../../../api/discounts.api';
import { TariffDTO } from '../../../api/DTO';
import { getAllTariffs } from '../../../api/tariffs.api';
import { BaseProps } from '../../../common/interfaces/base.props';


export const UpsertDiscount = ({ id }: BaseProps) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [tariffs, setTariffs] = useState<Array<TariffDTO>>([]);
  const history = useHistory();
  const MAX_DISCOUNT_PERCENT = 59;
  const viewMode: boolean = id ? true : false;


  useEffect(() => {
    getAllTariffs().then(tariffs => {
      // const mappedTariffs = tariffs.map((tariff) => {
      //   return {
      //     ...tariff,
      //     priceInCents: tariff.priceInCents / 100,
      //   }
      // });
      setTariffs(tariffs);
    });
  }, [ setTariffs ]);


  useEffect(() => {
    if (id) {
      getDiscountById(id).then(data => {
        form.setFieldsValue(data);
      });
    }
  }, [form.setFieldsValue, form, id]);


  const handleCreate = () => {
    const { tariffId, discountPercent } = form.getFieldsValue();

    if (discountPercent) {
      createDiscount({ tariffId, discountPercent }).then(() => {
        history.push('/discounts');
      });
    }
  }

  const handleUpdate = () => {
    if (id) {
      history.push('/discounts');
    }
  }


  return (
    <>
      <h1>{id ? 'Просмотр промокода' : 'Создание промокода'}</h1>
      <Form form={form}>
      <Form.Item
          label="Скидка, %"
          name="discountPercent"
          rules={[{ required: true, message: 'Процент скидки должен быть указан!' }]}
        >
          <InputNumber
            controls={false}
            disabled={viewMode}
            placeholder="Скидка, %"
            style={{ width: 300 }}
            max={MAX_DISCOUNT_PERCENT}
          />
        </Form.Item>
        <Form.Item
          label="Тариф"
          name="tariffId"
          rules={[{ required: true, message: 'Тариф должен быть указан!' }]}
        >
          <Select
            disabled={viewMode}
            placeholder="Тариф"
            style={{ width: 300 }}
          >
            {tariffs && tariffs.map(({ id, title }: TariffDTO) =>
              (<Option key={id} value={id}>{title}</Option>)
            )}
          </Select>
        </Form.Item>
        {/* <Form.Item
          label="Скидка, %"
          name="discountPercent"
          rules={[{ required: true, message: 'Процент скидки должен быть указан!' }]}
        >
          <InputNumber
            controls={false}
            disabled={viewMode || !form.getFieldValue('discountPercent')}
            placeholder="Скидка, %"
            style={{ width: 300 }}
            min={MIN_DISCOUNT_PERCENT}
          />
        </Form.Item> */}
      { id ?
        <Button onClick={handleUpdate}>Назад к списку</Button> :
        <Button onClick={handleCreate} htmlType="submit">Создать</Button>
      }
      </Form>
      {!id && (
        <Alert
            style={{ marginTop: '30px' }}
            message="Внимание!"
            description="Редактировать промокод после его создания будет невозможно!"
            type="warning"
            showIcon
          />
      )}
    </>
  );
}
