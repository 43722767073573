import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Select, Switch } from 'antd';
import { createMailing, readMailingById, updateMailing } from '../../../api/mailings.api';
import { BaseProps } from '../../../common/interfaces/base.props';
import { MessagesList } from '../messages-list/messages-list.component';
import { readAllCourses } from '../../../api/courses.api';
import { UpsertMailingCourseItem } from './interfaces/upsert-mailing-course-item.interface';
import { TimePicker } from '../../../components';
import { Language, languageGlossary } from '../../../enums/languages';
import { MailingResponseDTO } from '../../../types';


export const UpsertMailing = ({ id }: BaseProps) => {
  const { Option } = Select;
  const [mailing, setMailing] = useState<MailingResponseDTO>();
  const [coursesList, setCoursesList] = useState<Array<UpsertMailingCourseItem> | undefined>();
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      readMailingById(id).then(data => {
        setMailing(data);
        form.setFieldsValue({ ...data, courseId: data.course.id, course: undefined });
      });
    }
  }, [setMailing, form.setFieldsValue, form, id]);

  useEffect(() => {
    readAllCourses().then((courses) => {
      setCoursesList(courses);
    });
  }, []);

  const handleCreate = () => {
    createMailing(form.getFieldsValue()).then(() => {
      history.push('/mailings');
    });
  }

  const handleUpdate = () => {
    if (id) {
      updateMailing(id, form.getFieldsValue()).then(() => {
        history.push('/mailings');
      });
    }
  }

  const handleLandingButtonClick = () => {
    if (mailing?.id) {
      history.push(`/landing/${ mailing.id }`);
    }
  }

  return (
    <>
      <h1>{ id ? 'Редактирование рассылки' : 'Создание рассылки' }</h1>
      <Form form={form}>
        <Form.Item label="Название" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Ключ" name="key">
          <Input />
        </Form.Item>
        <Form.Item label="Видимость" name="isPublic">
          <Select placeholder="Видимость" style={{ width: 300 }}>
            <Option key={1} value={true}>Публичная</Option>
            <Option key={2} value={false}>Закрытая</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Курс" name="courseId">
          <Select placeholder="Курс" style={{ width: 500 }}>
            {coursesList && coursesList.map(({ id, title }: UpsertMailingCourseItem) =>
              (<Option key={id} value={id}>{title}</Option>)
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Язык" name="language">
          <Select placeholder="Язык" style={{ width: 200 }}>
            {languageGlossary.map((language: Language) => (
							<Option key={language.id} value={language.isoCode}>
								{language.name}
							</Option>
						))}
          </Select>
        </Form.Item>
        <Form.Item label="Отправлять данные консультанта" name="sendConsultantInfo" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Время отправки данных консультанта" name="consultantDataMessageDelaySeconds">
          <TimePicker showDays={true} />
        </Form.Item>
      </Form>
      { id ?
        <Button onClick={handleUpdate}>Обновить</Button> :
        <Button onClick={handleCreate}>Создать</Button>
      }
      { id &&
        <MessagesList mailingId={Number(id)} courseId={Number(mailing?.course.id)} />
      }
      <Button onClick={() => handleLandingButtonClick()}>Редактировать лендинг</Button>
    </>
  );
}
