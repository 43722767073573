import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { SiderMenu } from '../modules/sider-menu/sider-menu.component';
import { Router } from '../router/router.component';
import '../utils/helpers/fix-console-warning';

import './app.style.css';

const App = () => {
  return (
      <BrowserRouter>
          <Layout>
              <Layout.Sider>
                  <SiderMenu />
              </Layout.Sider>
              <Layout>
                  <Layout.Content className="main">
                      <Router />
                  </Layout.Content>
              </Layout>
          </Layout>
      </BrowserRouter>
  );
}

export { App };
